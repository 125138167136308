.logo{
    width:100%;
    margin:0 auto;
    position: relative;
    .mtop{
        @include position(absolute,$top:0,$left:0);
        animation: startmove 2.5s infinite;
        opacity: 0;
    }
}
@keyframes startmove{
    0%{opacity: 0;}      
    50%{opacity: 1;}
    100%{opacity: 0;}
}
.mtitle{
    h2{
        @include font-color(white,700);
        letter-spacing: 0.1em;
        line-height: 1;
        @include media-breakpoint-down(xs){
            font-size: pxToEm(40);
        }
        @include media-breakpoint-up(sm){
            font-size: pxToEm(48);
        }
    }
    .line{
        margin-top:0.75em;
    }
    .slogn{
        font-size: pxToEm(22);
        line-height: 1.5;
        color:white;
        @include media-breakpoint-down(xs){
            font-size: pxToEm(20);
        }
        @include media-breakpoint-up(md){
            letter-spacing: 0.1em;
        }
    }
    .mdes{
        font-size: pxToEm(18);
        line-height:1.6;
        letter-spacing: 0.075em;
    }
    &.fblue{        
        h2,.slogn{
            color:#162f54;
        }
        .slogn{
            margin-top: 1em;
            @include media-breakpoint-down(xs){
                margin-bottom: 1em;
            }
            @include media-breakpoint-up(sm){
                margin-bottom: 2em;
            }
        }
        .mitem{
            line-height: 1.6;
            color:black;
            @include media-breakpoint-down(xs){
                font-size:pxToEm(16);
            }
            @include media-breakpoint-up(sm){
                font-size:pxToEm(18);
                letter-spacing: 0.075em;
            }
        }
    }
}
.bg-repeaty{
    background:repeat-y url(../images/light_bg_2000w.jpg) center top / 2000px;
}
.bdr{
    display: flex;
    align-items: center;
    position: relative;
    border:1px solid #f2b94a;
    padding:2% 6%;
    @include media-breakpoint-down(md){
        padding:6%;
        width:90%;
        max-width: 500px;
    }
    @include media-breakpoint-up(lg){
        padding:2% 6%;
        height: 100%;
    }
    .mtitle{
        flex:0 0 100%;
        width:100%;
    }
    &:before{
        content:' ';
        width:calc(100% + 14px);
        height: calc(100% + 14px);
        @include position(absolute,$top:-7px,$left:-7px);
        border:2px solid #f2b94a;
    }
    .diamonds{
        @include position(absolute,$left:0,$top:0);
        width:100%;
        height: 100%;
        > div{
            width:60px;
        }
        div:nth-child(1){
            @include position(absolute,$left:-30px,$top:-30px);
        }
        div:nth-child(2){
            @include position(absolute,$right:-30px,$top:-30px);
        }
        div:nth-child(3){
            @include position(absolute,$left:-30px,$bottom:-15px);
        }
        div:nth-child(4){
            @include position(absolute,$right:-30px,$bottom:-15px);
        }
    }
}
.menuTop{
    &#indx-7{
        transform: translateY(-30px);
    }
}
.pd15-sm{
    padding:0 15px;
    @include media-breakpoint-down(xs){
        
    }
    @include media-breakpoint-between(sm,md){
        padding:6vh 6vw;
    }
}
.indx-1{
    @include media-breakpoint-down(sm){
        background:no-repeat url(../images/1/banner_2000x1125_mb.jpg) center bottom/ cover;
    }
    @include media-breakpoint-up(md){
        background:no-repeat url(../images/1/banner_2000x1125.jpg) center bottom / cover;
    }
    width:100%;
    height: 100vh;
    position: relative;
    .txt{
        margin: 0 auto;
        @include media-breakpoint-down(xs){
            @include position(absolute,$top:40%,$left:50%);
            width:200px;
            transform: translate(-50%,-50%);
        }
        @include media-breakpoint-up(sm){
            @include position(absolute,$top:5%,$left:50%);
            width:225px;
            transform: translateX(-50%);
        }
        @media (min-width:1600px){
            // transform: scale(1.5) translateX(-50%);
            // transform-origin: left;
            top:25%;
        }
    }
}
.indx-2{
    // background-image: linear-gradient(to top, #162142, #1479b9, #162142);
    overflow: hidden;
    // @include media-breakpoint-down(sm){
    //     padding-top: 5em;
    // }
    // @include media-breakpoint-up(lg){
    //     display: flex;
    //     padding-top:7.5em;
    //     padding-bottom: 5.6em;
    //     .txtbox{
    //         flex:0 0 43%;
    //         width:43%;
    //         position: relative;
    //         z-index: 10;
    //     }        
    //     .imgbox{
    //         flex:0 0 57%;
    //         width:57%;
    //         position: relative;
    //     }
    // }
    .building{
        position: relative;
        @include media-breakpoint-down(sm){
            background:no-repeat url(../images/2/building.jpg) center top / cover;
            height:280px;
        }
        @include media-breakpoint-up(md){
            background:no-repeat url(../images/2/building.jpg) center top / 2000px;
            height:528px;
        }
    }
    .bgimg{
        @include media-breakpoint-down(md){    
            padding:4vh 15px;
            background:no-repeat url(../images/2/bg_2000w_mb.jpg) center bottom / cover;
        }
        @include media-breakpoint-up(lg){
            padding:12vh 15px;
            background:no-repeat url(../images/2/bg_2000w.jpg) center bottom / cover;
        }
    }
    .mtitle{
        position: relative;
        z-index: 5;
    }
    .slogn{
        margin-top: 0.875em;
    }
    .mdes{
        color:white;
    }
    .bdr{        
        margin:0 auto;
        @include media-breakpoint-down(md){    
            padding:4vh 0;
        }
        @include media-breakpoint-up(lg){
            padding:7vh 0;
            width:88%;
        }
        @media (min-height:800px) and (min-width:1600px){
            padding:9vh 0;
        }
    }    
    .pdlr{
        @include media-breakpoint-down(xs){
            padding:0 3%;
        }
        @include media-breakpoint-up(sm){
            padding:0 6%;
        }
    }
    .adva{
        // background-image: linear-gradient(to left, #b89109, #ffee9d, #be9234, #aa883f, #fcda3c);
        @include gradient-animation(#b89109,#ffee9d);
            background-size: 200% 200%;   
            animation:gradeffect infinite 3s linear;  
        width:100%;
        font-size: pxToEm(24);
        letter-spacing: 0.078em;
        line-height: 1;
        padding:0.75em 0;
        @include media-breakpoint-down(sm){
            margin-top:1.5em;
        }
        @include media-breakpoint-up(md){
            margin-top:1.5em;
        }
    }
    .itme-3{
        > div{        
            max-width: 44.5em;
            margin:auto;  
        }
        > div{
            @include media-breakpoint-down(sm){
                text-align: center;
                margin-top: 3em;
            }
            @include media-breakpoint-up(md){
                display: flex;
                align-items: center;
                margin-top: 2em;
            }
            @include media-breakpoint-up(lg){                
                br{
                    display: none;
                }
            }
        }
        > div:nth-child(1){
            @include media-breakpoint-down(sm){
                margin-top: 2em;
            }
            @include media-breakpoint-up(md){
                margin-top: 1.5em;
            }
        }
        .mimg{            
            img{
                height: 60px;
            }
            @include media-breakpoint-down(sm){
                img{
                    margin-bottom: 1em;
                }
            }
            @include media-breakpoint-up(md){
                flex:0 0 120px;
                width:120px;
                // border-right:2px solid #bca465;
                margin-right: 20px;
                // padding-right: 20px;
                text-align: center;
            }
        }
        .txt{
            font-size: pxToEm(20);
            color:white;
            letter-spacing: 0.025em;
            line-height: 1.35;
        }
    }
    @media (min-width:992px) and (max-width:1199px){
        // .imgbox > img{
        //     max-width: 90%;
        // }
        .itme-3{
            font-size: 12px;
            .mimg{
                flex:0 0 90px;
                width:90px;
            }
            .mimg img{
                height: 40px;
            }
        }
    }
    // .build{
    //     @include media-breakpoint-down(md){
    //         margin-left: -13%;
    //         width:126%;
    //     }
    //     @include media-breakpoint-up(lg){
    //         @include position(absolute,$bottom:-7%,$left:-4%);
    //         width:116%;
    //     }
    //     @media (min-width:1600px){
    //         bottom:-12%;
    //         width:110%;
    //     }
    // }
}
.indx-3{
    background-color: white;
    @include media-breakpoint-down(xs){
        padding-top: 2.5em;
        padding-bottom: 1em;
    }
    @include media-breakpoint-up(sm){
        padding:4.5em 0 4em 0;
    }
    @include media-breakpoint-up(lg){
        .mflex{
            display: flex;
            margin-bottom: 3em;
        }
        .txtbox{
            flex:0 0 43%;
            width:43%;
            order:2;
            margin-left: 4.3%;
        }
        .imgbox{
            flex:0 0 48%;
            width:48%;
            order:1;
            overflow: hidden;
        }
    }
    .bdr{
        @include media-breakpoint-down(md){
            margin:0 auto 2em;
        }
        @include media-breakpoint-only(md){
            margin-bottom:5em;
        }
        @include media-breakpoint-up(lg){
            flex:0 0 90%;
            width:90%;
        }
    }    
    .imgbox{
        @include media-breakpoint-down(md){
            margin:0 auto 0.5em;
        }
        @include media-breakpoint-between(sm,md){
            width:calc(84% - 16px);
        }
    }
    .slick-3{        
        max-width: 84%;
        margin:0 auto;
        .slick-slide{
            cursor: pointer;
        }
    }
    .btn-slick-prev{
        @include media-breakpoint-down(xs){
            left:-0.5em;
        }
        @include media-breakpoint-up(sm){
            left:4%;
        }
    }
    .btn-slick-next{
        @include media-breakpoint-down(xs){
            right:-0.5em;
        }
        @include media-breakpoint-up(sm){
            right:4%;
        }
    }
    .btn-slick-prev,.btn-slick-next{
        cursor: pointer;
        @include position(absolute,$top:50%);
        transform: translateY(-50%);
        @include media-breakpoint-up(md){
            display: none;
        }
        .btm{
            opacity: 1;
            transition: opacity 0.6s;
        }
        .top{
            @include position(absolute,$top:0,$left:0);
            opacity: 0;
            transition: opacity 0.6s;
        }
        &:hover{
            .btm{
                opacity: 0;
            }
            .top{
                opacity: 1;
            }
        }
    }
}
.indx-4{
    .imgbox > div{
        position: relative;
    }
    .logo{
        @include media-breakpoint-down(xs){
            height: 22px;
            @include position(absolute,$top:0%,$left:306px);
        }
        @include media-breakpoint-up(sm){
            @include position(absolute,$top:0%,$left:38.7%);
            width:4%;
        }
    }
    .imgbox{
        @include media-breakpoint-down(xs){  
            height: 350px;     
            width: 100%;
            overflow: hidden;    
            position: relative; 
            > div{
                height: 100%;
                display: flex;
                overflow: hidden;
                @include position(absolute,$left:50%);
                transform: translateX(-50%);
                animation: image-move 15s linear infinite;
            }
            img{
                height: 100%;
                max-width: initial;
            }
        } 
    }
    
}
@keyframes image-move {
	0% {transform: translatex(-40%);}
	50% {transform: translatex(-60%);}
	100% {transform: translatex(-40%);}
}
.indx-5{
    @include media-breakpoint-up(lg){
        display: flex;
        .txtbox{
            flex:0 0 49%;
            width:49%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding:2.5em;
        }
        .bdr{
            flex:0 0 83%;
            width:83%;
        }
        .imgbox{
            flex:0 0 51%;
            width:51%;
        }
    }
    .txtbox{
        @include media-breakpoint-down(md){
            padding-top:2em;
            padding-bottom: 2em;
        }
        @include media-breakpoint-only(md){
            padding-top:4em;
            padding-bottom: 4em;
        }
    }
    .bdr{
        @include media-breakpoint-down(md){
            margin:0 auto;
        }
    }
}
.indx-6{
    position: relative;
    text-align: center;
    z-index: 10;
    margin-top: -5px;
    .mtitle{
        @include media-breakpoint-down(md){
            padding-top:2em;
            padding-bottom: 4em;
            background:no-repeat url(../images/6/view.png) center bottom / cover;
        }
        @include media-breakpoint-only(md){
            padding-top:4em;
            padding-bottom: 8em;
        }
        @include media-breakpoint-up(lg){
            max-width: 800px;
            width:calc(100% - 40px);
            @include position(absolute,$top:15vh,$left:50%);
            transform: translateX(-50%);
        }
    }
    .mimg{
        @include media-breakpoint-down(md){
            display: none;
        }
    }
    .wline{
        width:100%;
        height: 2px;
        background-color: white;
        @include media-breakpoint-down(md){
            max-width: 600px;
            margin:1.56em auto;
        }
        @include media-breakpoint-up(lg){
            margin:1.56em 0;
        }
    }
    .mdes{
        max-width: 31em;
        width:100%;
        background-color: rgba(black,0.2);
        @include font-color(white,500);
        text-shadow: 0 0 9.6px rgba(0, 0, 0, 0.45);
        padding:1.25em 1.25em;
        margin: 1.125em auto 0;
    }
}
.sucbg{
    @include media-breakpoint-down(sm){
        padding-bottom: 2em;
        padding-top: 90px;
        background:no-repeat url(../images/7/success_bg_mb.jpg) #162142 center top / cover;
    }
    @include media-breakpoint-up(md){        
        background:no-repeat url(../images/7/success_bg.jpg) #162142 center top / cover;
        padding-top: 160px;
    }
    @include media-breakpoint-up(lg){
        padding-bottom: 130px;
    }    
    .powder{
        background:no-repeat url(../images/7/gold_powder.png) center top;
        width:100%;
        height: 100%;
        @include position(absolute,$left:0);
        @include media-breakpoint-down(sm){
            background-size: 1100px;
            top:-60px;
        }
        @include media-breakpoint-up(md){
            background-size: 1900px;
            top:-80px;
        }
    }
}
.indx-7{    
    line-height: 1.25;
    @include font-color(white,500);
    letter-spacing: 0.075em;
    .slogn{
        margin-top: 0.875em;
        margin-bottom: 3em;
    }
    .team{
        display: flex;
        justify-content: space-around;
        > div{
            position: relative;
        }        
        // @include media-breakpoint-down(sm){
        //     flex-wrap: wrap;
        //     > div{
        //         flex:0 0 100%;
        //         margin-bottom: 3em;
        //     }
        // }
        @include media-breakpoint-down(md){
            flex-wrap: wrap;
            > div{
                flex:0 0 50%;
                margin-bottom: 3em;
            }
        }
        @include media-breakpoint-only(md){
            padding:15px;
        }
        @include media-breakpoint-up(lg){
            > div:nth-child(1){
                padding-right:25px;
            }
            > div:nth-child(4){
                padding-left:30px;
            }
        }
    }
    .mname{
        @include position(absolute,$top:3em);
    }
    .p1{
        @include media-breakpoint-down(md){
            left:0em;
        }
        @include media-breakpoint-up(lg){
            left:0;
        }
    }
    .p2{
        @include media-breakpoint-down(sm){
            left:-1em;
            + .mimg img{
                margin-left: 10%;
            }
        }
        @include media-breakpoint-up(md){
            left:-0.7em;
        }
    }
    .p3{
        @include media-breakpoint-down(sm){
            left:0em;
        }
        @include media-breakpoint-up(md){
            left:1.5em;
        }
    }
    .p4{
        @include media-breakpoint-down(sm){
            left:1em;
            + .mimg img{
                margin-left: 10%;
            }
        }
        @include media-breakpoint-up(md){
            left:4.5em;
        }
    }
    .mname div:nth-child(1){
        font-size: pxToRem(16);
    }
    .mname div:nth-child(2){
        font-size: pxToRem(20);
    }
    .mimg{
        text-align: center;
        @include media-breakpoint-down(md){     
            img{
                width:90%;
            }
        }
    }
    .com div:nth-child(1){
        line-height: 1.3;
        @include media-breakpoint-down(xs){
            font-size: pxToRem(18);
            margin-bottom: 0.25em;
            margin-top:0.5em;
        }
        @include media-breakpoint-up(sm){
            font-size: pxToRem(24);
            margin-bottom: 0.01em;
            margin-top:1.25em;
        }
    }
    .com div:nth-child(2){
        font-size: pxToRem(16);
    }
}
.indx-8{
    margin-top: 5em;
    @include media-breakpoint-between(sm,md){
        margin-top: -6em;
    }
    .col-12{
        padding-left: 0;
        padding-right: 0;
    }
    .mtitle{
        @include media-breakpoint-between(sm,md){
            width:90%;
            margin-left: 0.5em;
            margin-bottom: 0.5em;
        }
    }
    .flex-center{
        align-items: center;
        display: flex;
    }
    .slogn{
        margin-top: 1em;
    }
    .mdes{
        color:white;
        font-size: pxToRem(18);
        line-height: 1.6;
        letter-spacing: 0.075em;
        margin-top:2.5em ;
    }
    .mflex{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }
    .flex-4{  
        @include media-breakpoint-down(sm){
            width:100%;
            flex:0 0 100%;
            img{
                width:100%;
            }
        }
        @include media-breakpoint-up(md){  
            flex:0 0 50%;
            width:50%;
            margin-top: 0.5em;
            margin-bottom: 0.5em;
        }
    }
    .flex-2{
        flex:0 0 50%;
        width:50%;
        @include media-breakpoint-down(sm){  
            .txt{
                width:calc(100% - 0.5em);
            }       
            &:nth-child(1){
                padding-right:0.5em;
                .txt{
                    left:0;
                }
            }
            &:nth-child(2){
                padding-left: 0.5em;
                .txt{
                    left:0.5em;
                }
            }
        }
    }
    .flex-4:not(.mflex),.flex-2{ 
        position: relative;
        @include media-breakpoint-down(sm){
            margin-top: 1em;
        }
        @include media-breakpoint-up(md){         
            padding-left: 0.5em;
            padding-right: 0.5em;
        }
    }
    
    .mimg{
        overflow: hidden;
        img{          
            transform: scale(1);    
            transition: transform 0.6s;
            &:hover{
                transform: scale(1.2);
            }
        }
    }
    .txt{
        text-align: center;
        height: 2em;
        line-height: 2em;
        letter-spacing: 0.1em;
        font-size: pxToRem(16);
        color:white;
        background-color: rgba(black,0.75);        
        @include media-breakpoint-down(sm){
            width:100%;
            @include position(absolute,$bottom:0,$left:0);
        }
        @include media-breakpoint-up(md){
            width:calc(100% - 1em);
            @include position(absolute,$bottom:0,$left:0.5em);
        }
    }
}
.indx-9{
    background:no-repeat url(../images/9/form_bg_2000px.png) center top / 2000px;
    @include media-breakpoint-down(sm){
        padding-bottom: 50px;
        padding-top: 10px;
        background-size: cover;
    }
    @include media-breakpoint-up(md){        
        padding-bottom: 100px;
        padding-top: 60px;
    }
    .mtitle{
        margin-bottom: 2.5em;
    }
    .logo{
        width:65px;
        margin-bottom: 12px;
    }
    .content{
        position: relative;
        @include media-breakpoint-down(sm){
            padding:20px 0;
        }
        @include media-breakpoint-up(md){
            padding:60px 0;
        }
        &:before,&:after{
            content: '';
            width:100%;
            height: 100%;
            @include position(absolute,$left:0);
        }
        &:before{
            background:no-repeat url(../images/9/form-top.png) center top / contain;
            top:0;
        }
        &:after{
            background:no-repeat url(../images/9/form-btm.png) center bottom / contain;
            bottom:0;
        }
    }
    form.row{
        @include media-breakpoint-down(sm){
            padding-bottom: 20px;
        }
    }
    .col-12{
        position: relative;
        z-index: 10;
        @include media-breakpoint-down(sm){
            padding-left: 25px;
            padding-right: 25px;
        }
    }
}
.indx-10{
    overflow-x: hidden;
    .logo{
        width:100px;
        margin:0 auto 30px;
    }
    .map{
        position: relative;
        img{
            @include position(absolute,$top:0);
            transform: translateX(-50%);
        }
        @include media-breakpoint-down(xs){
            height: 252px;
            img{
                left:30%;
                max-width: 1400px;
            }
        }
        @include media-breakpoint-up(sm){
            height: 360px;
            img{
                left:50%;
                max-width: 2000px;
            }
        }
    }
    .infobox{
        background:no-repeat url(../images/10/bg.jpg) center / cover;
        padding-top: 10px;
        padding-bottom: 50px;        
        transform: translateY(-4.5em);        
        @include media-breakpoint-down(sm){
            display: none;
        }
    }
    .info{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        a.w30{
            flex:0 0 calc((100% - 40px) / 3);
            margin-bottom: 15px;
        }
    }
}
.f_info .infobtn{
    @include media-breakpoint-down(sm){
        @include position(fixed,$bottom:0);
        width:100%;
        z-index: 99;
        display: flex;
        justify-content: space-around;
        a{
            background-color: $blue;
            flex:0 0 25%;
            border:1px solid rgba(white,0);
            text-align: center;
            padding:18px 12px;
            font-size: pxToEm(14);
            @include font-color(white);
        }
    }
    @include media-breakpoint-up(md){
        display: none;
    }
}
footer{
    @include media-breakpoint-down(sm){
        padding-top: 2em;
        padding-bottom: 4em;
    }
    ul.temp{
        font-size: pxToRem(16);
        color:$blue;
        margin-top: 3em;
        line-height: 1.5;
        display: flex;
        flex-wrap: wrap;
        li{
            display: flex;
            @include media-breakpoint-down(sm){
                width:100%;
                flex:0 0 100%;
                margin-bottom: 1em;
            }
            @include media-breakpoint-up(md){
                width:50%;
                flex:0 0 50%;
                letter-spacing: 0.1em;
                margin-bottom: 2em;
            }
        }
        i{
            @include font-color($blue);
            flex:0 0 6em;
            @include media-breakpoint-down(sm){
            }
            @include media-breakpoint-up(md){
                margin-right: 1em;
            }
        }
        i:before{
            content:' ';
            display: inline-block;
            width:2px;
            height: 1em;
            margin-right: 12px;
            background-color: $blue;
            vertical-align: -0.15em;
            @include media-breakpoint-down(sm){
                
            }
        }
    }
}
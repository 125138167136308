.form_des{
    @include font-color(black);
    text-align: center;
    font-size: pxToRem(18);
    margin-bottom: 1.125em;
    line-height: 1.5;
    @include media-breakpoint-down(xs){
        // font-size: pxToEm(14);
        margin-bottom: 25px;
        i{
            display: block;
        }
    }
    @include media-breakpoint-up(sm){
       
    }
}
form{
    background:repeat-y url(../images/9/form-repeat.png) center / contain;
    overflow: hidden; 
    letter-spacing: 0.01em;
    .form-group{
        margin-bottom: 8px;
    }
    input,select,textarea{
        outline: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        box-shadow:none;
        width:100%;
        background-color:#e8e8e8;
        font-size: pxToRem(18);
        @include font-color(black,300);
        &:focus { outline: none; }
    }
    input,select{
        height:33px;
        padding:0px 8px;
        border:none;
        @include placeholder {
            font-size: pxToRem(16);
            @include font-color(black);
            letter-spacing: 0.01em;
        }
    }
    //select
    select::-ms-expand { display: none; }    
    select{
        background:url('../images/select_down.png') #e8e8e8 no-repeat calc(100% - 20px) 55% / 12px;
        padding:3px 15px 3px 8px ;
        outline: none;
    }
    @media screen\0 {
        select{
        background:url("../images/select_down.png") #e8e8e8 no-repeat calc(100% - 20px) 55% / 12px;
        }
    }
    label{
        line-height: 1;
        margin-bottom: 6px;
        @include font-color(black,500);
        @include media-breakpoint-down(xs){
        }
        @include media-breakpoint-up(sm){
            font-size: pxToRem(16); 
        }
    }
    .with-errors{
        flex:0 0 100%;
        min-height:16px;
        line-height:1;
        letter-spacing: 0.8px;
        @include font-color(#104ed8);  
        display:block;
        margin-top: 4px;
        font-size: pxToEm(14); 
        // @include media-breakpoint-down(xs){
        //     font-size: pxToEm(14); 
        // }
        // @include media-breakpoint-up(sm){
        //     font-size: pxToEm(16); 
        // }
    }
    a[type="submit"]{
        outline: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        box-shadow:none;
        line-height: 40px;
        height: 40px;
        width:184px;
        text-align: center;
        @include font-color(black,700);
        letter-spacing: 1px;
        font-size: pxToEm(20);
        margin-top: 15px;
        display: inline-block;
        background-color: #b9930b;
        position: relative;
        opacity: 1;
        span{
            position: relative;
        }
        &:before{
            content: '';
            @include position(absolute,$top:0,$left:0);
            height: 100%;
            width:100%;
            // background:no-repeat url(../images/9/form-btn.png) center / cover;
            @include gradient-animation(#b89109,#ffee9d);
            background-size: 200% 200%;   
            animation:gradeffect infinite 1s linear;   
        }
        &:hover{
            opacity: 0.5;
        }
    }
    //agree
    .check_radi {
        padding-left: 36px;
        line-height: 22px;
        text-align: left;
        width: auto;
        position: relative;
        cursor: pointer;
        user-select: none;
        @include media-breakpoint-down(xs){
            padding-left: 30px;
        }
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            left: 0;
            top:0;
            z-index: 5;
            width:24px;
            height: 24px;
        }
        .checkmark {
            @include position(absolute,$top:0,$left:0);
            height: 24px;
            width: 24px;
            background-color: white;
            border: solid 1px black;
        }
        .checkmark:after {
            content: "";
            @include position(absolute,$top:-5px,$left:5px);
            display: none;
            width: 24px;
            height: 24px;
            background: url('../images/agree_yes.png') no-repeat center / contain ;
        }
        input:checked~.checkmark:after {
            display: block;
        }
        a[data-popup]{
            color:#104ed8;
        }
    }
    textarea{
        @include media-breakpoint-down(sm){
            min-height: 241px;
        }
        @include media-breakpoint-up(md){
            min-height: 330px;
        }
    }
    .textarea-wrapper{
        @include media-breakpoint-down(sm){
            height: 295px;
        }
        @include media-breakpoint-up(md){
            height: 358px;
        }
        overflow: hidden;
        padding:13px 11px 13px 15px;
        background-color: #e8e8e8;
        textarea{
            overflow: hidden;
            border:none;
            padding:0;
            text-rendering: auto;
            -webkit-writing-mode: horizontal-tb !important;
            cursor:text;
        }
    }
}

@keyframes gradeffect {
    0% {
      background-position: top left;
    }
    100% {
      background-position: top right;
    }
}
@charset "UTF-8";
/******************************************/
/*		bootstrap-okadmin
/******************************************/
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+TC:300,400,500,700&display=swap");
.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::placeholder {
    color: #868e96;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px); }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

select.form-control-sm:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px); }

.form-control-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control-lg:not([size]):not([multiple]) {
  height: calc(2.875rem + 2px); }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #868e96; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  width: 250px;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745; }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
  .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip, .was-validated
  .custom-select:valid ~ .valid-feedback,
  .was-validated
  .custom-select:valid ~ .valid-tooltip,
  .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    background-color: #71dd8a; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }
  .was-validated .custom-file-input:valid ~ .custom-file-label::before, .custom-file-input.is-valid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  width: 250px;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
  .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip, .was-validated
  .custom-select:invalid ~ .invalid-feedback,
  .was-validated
  .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    background-color: #efa2a9; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }
  .was-validated .custom-file-input:invalid ~ .custom-file-label::before, .custom-file-input.is-invalid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

/* > reset */
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

@-ms-viewport {
  width: device-width; }

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

a,
area,
button,
[role="button"],
input:not([type="range"]),
label,
select,
summary,
textarea {
  touch-action: manipulation; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #868e96;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: .5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.border {
  border: 1px solid #e9ecef !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #868e96 !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-0 {
  border-radius: 0 !important; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

[dir='rtl'] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.pc_Banner .slick-slide {
  height: 371px; }

.mo_Banner .slick-slide {
  height: 200px; }

/* Slider */
/* Icons */
@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;
  src: url("../assets/slick.eot");
  src: url("../assets/slick.eot?#iefix") format("embedded-opentype"), url("../assets/slick.woff") format("woff"), url("../assets/slick.ttf") format("truetype"), url("../assets/slick.svg#slick") format("svg"); }

/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
  z-index: 99; }

/*.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
    background: transparent;
}*/
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1; }

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 1; }

.slick-prev:before,
.slick-next:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;
  opacity: 1;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px;
  /*    background: url(../images/are.png) 8px 0 no-repeat;*/ }

.slick-next {
  right: -25px;
  /*    background: url(../images/are.png) -10px 0 no-repeat;*/ }

/* Dots */
.slick-dots {
  position: absolute;
  display: block;
  width: 100%;
  padding: 0;
  margin: -39px 0 0 0;
  list-style: none;
  text-align: center; }

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 11px;
  height: 11px !important;
  margin: 0 10px;
  padding: 0;
  cursor: pointer; }

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent; }

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
  background: transparent !important; }

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1; }

.slick-dots li button:before {
  font-size: 6px;
  line-height: 20px;
  display: table;
  position: absolute;
  top: 2px;
  left: 2px;
  width: 7px;
  height: 7px;
  -webkit-border-radius: 99em;
  -moz-border-radius: 99em;
  border-radius: 99em;
  background: #fff;
  content: '';
  text-align: center;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-dots li.slick-active button:before {
  background: #000; }

.topContent .slick-next, .topContent .slick-prev {
  display: none  !important; }

.topContent .slick-dots li {
  width: 20px; }

.topContent .slick-dots {
  position: relative;
  margin: 29px 0 0 0; }

.tagContent .slick-dots {
  margin: 0px 0 0 0; }

.tagContent .slick-dots li button:before {
  border: none; }

.exposeCase .topicContent .slick-dots {
  margin: 0px 0 0 0; }

.exposeCase .topicContent .slick-dots li {
  width: 20px; }

.exposeCase .topicContent .slick-dots li button:before {
  border: none;
  background: #959595; }

.exposeCase .topicContent .slick-dots li.slick-active button:before {
  border: none;
  background: #6bad3d; }

.learningBox .exposeCase .topicContent li {
  margin: 0px 4px 20px 0; }

.exposeCase .slick-prev {
  width: 46px;
  height: 108px;
  display: block;
  /* background: url(../images/le_ribtn.jpg) top left no-repeat; */
  left: -46px; }

.exposeCase .slick-next {
  width: 46px;
  height: 108px;
  display: block;
  /* background: url(../images/ex_ribtn.jpg) top left no-repeat; */
  right: -29px; }

.navigation .tagContent .slick-dots {
  margin: -13px 0 0 0;
  width: auto;
  text-align: right;
  position: relative;
  bottom: -11px; }

.eventBox .navigation .tagContent .slick-dots {
  margin: -13px 0 0 0;
  width: auto;
  text-align: right;
  position: relative;
  bottom: -26px; }

.navigation .slick-dots li button:before {
  background: #959595; }

.navigation .slick-dots li.slick-active button:before {
  background: #6bad3d; }

.recentBox .slick-dots {
  display: none !important; }

.recentBox .slick-list {
  width: 90%;
  max-width: 1000px;
  margin: 0 auto; }

.banner .slick-prev, .recentBox .slick-prev {
  left: 0px;
  width: 40px;
  height: 93px; }

.banner .slick-next, .recentBox .slick-next {
  right: 0px;
  width: 40px;
  height: 93px; }

.mCustomScrollbar {
  -ms-touch-action: pinch-zoom;
  touch-action: pinch-zoom; }

.mCustomScrollbar.mCS_no_scrollbar, .mCustomScrollbar.mCS_touch_action {
  -ms-touch-action: auto;
  touch-action: auto; }

.mCustomScrollBox {
  position: relative;
  overflow: hidden;
  height: 100%;
  max-width: 100%;
  outline: 0;
  direction: ltr; }

.mCSB_container {
  overflow: hidden;
  width: auto;
  height: auto; }

.mCSB_inside > .mCSB_container {
  margin-right: 30px; }

.mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-right: 0; }

.mCS-dir-rtl > .mCSB_inside > .mCSB_container {
  margin-right: 0;
  margin-left: 30px; }

.mCS-dir-rtl > .mCSB_inside > .mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-left: 0; }

.mCSB_scrollTools {
  position: absolute;
  width: 16px;
  height: auto;
  left: auto;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: .75;
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)"; }

.mCSB_outside + .mCSB_scrollTools {
  right: -26px; }

.mCS-dir-rtl > .mCSB_inside > .mCSB_scrollTools, .mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools {
  right: auto;
  left: 0; }

.mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools {
  left: -26px; }

.mCSB_scrollTools .mCSB_draggerContainer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: auto; }

.mCSB_scrollTools a + .mCSB_draggerContainer {
  margin: 20px 0; }

.mCSB_scrollTools .mCSB_draggerRail {
  width: 2px;
  height: 100%;
  margin: 0 auto;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px; }

.mCSB_scrollTools .mCSB_dragger {
  cursor: pointer;
  width: 100%;
  height: 30px;
  z-index: 1; }

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  position: relative;
  width: 4px;
  height: 100%;
  margin: 0 auto;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  text-align: center; }

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar, .mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  width: 12px; }

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 8px; }

.mCSB_scrollTools .mCSB_buttonDown, .mCSB_scrollTools .mCSB_buttonUp {
  display: block;
  position: absolute;
  height: 20px;
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
  cursor: pointer; }

.mCSB_scrollTools .mCSB_buttonDown {
  bottom: 0; }

.mCSB_horizontal.mCSB_inside > .mCSB_container {
  margin-right: 0;
  margin-bottom: 30px; }

.mCSB_horizontal.mCSB_outside > .mCSB_container {
  min-height: 100%; }

.mCSB_horizontal > .mCSB_container.mCS_no_scrollbar_x.mCS_x_hidden {
  margin-bottom: 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal {
  width: auto;
  height: 16px;
  top: auto;
  right: 0;
  bottom: 0;
  left: 0; }

.mCustomScrollBox + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal, .mCustomScrollBox + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: -26px; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal a + .mCSB_draggerContainer {
  margin: 0 20px; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 2px;
  margin: 7px 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 30px;
  height: 100%;
  left: 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 4px;
  margin: 6px auto; }

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar, .mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  height: 12px;
  margin: 2px auto; }

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 8px;
  margin: 4px 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft, .mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
  display: block;
  position: absolute;
  width: 20px;
  height: 100%;
  overflow: hidden;
  margin: 0 auto;
  cursor: pointer; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft {
  left: 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
  right: 0; }

.mCSB_container_wrapper {
  position: absolute;
  height: auto;
  width: auto;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-right: 30px;
  margin-bottom: 30px; }

.mCSB_container_wrapper > .mCSB_container {
  padding-right: 30px;
  padding-bottom: 30px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_vertical {
  bottom: 20px; }

.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  right: 20px; }

.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden + .mCSB_scrollTools.mCSB_scrollTools_vertical {
  bottom: 0; }

.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_scrollTools.mCSB_scrollTools_horizontal, .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden + .mCSB_scrollTools ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  right: 0; }

.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  left: 20px; }

.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden + .mCSB_scrollTools ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  left: 0; }

.mCS-dir-rtl > .mCSB_inside > .mCSB_container_wrapper {
  margin-right: 0;
  margin-left: 30px; }

.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden > .mCSB_container {
  padding-right: 0; }

.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden > .mCSB_container {
  padding-bottom: 0; }

.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-right: 0;
  margin-left: 0; }

.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden {
  margin-bottom: 0; }

.mCSB_scrollTools, .mCSB_scrollTools .mCSB_buttonDown, .mCSB_scrollTools .mCSB_buttonLeft, .mCSB_scrollTools .mCSB_buttonRight, .mCSB_scrollTools .mCSB_buttonUp, .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  -webkit-transition: opacity .2s ease-in-out,background-color .2s ease-in-out;
  -moz-transition: opacity .2s ease-in-out,background-color .2s ease-in-out;
  -o-transition: opacity .2s ease-in-out,background-color .2s ease-in-out;
  transition: opacity .2s ease-in-out,background-color .2s ease-in-out; }

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail, .mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar, .mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail, .mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar {
  -webkit-transition: width .2s ease-out .2s,height .2s ease-out .2s,margin-left .2s ease-out .2s,margin-right .2s ease-out .2s,margin-top .2s ease-out .2s,margin-bottom .2s ease-out .2s,opacity .2s ease-in-out,background-color .2s ease-in-out;
  -moz-transition: width .2s ease-out .2s,height .2s ease-out .2s,margin-left .2s ease-out .2s,margin-right .2s ease-out .2s,margin-top .2s ease-out .2s,margin-bottom .2s ease-out .2s,opacity .2s ease-in-out,background-color .2s ease-in-out;
  -o-transition: width .2s ease-out .2s,height .2s ease-out .2s,margin-left .2s ease-out .2s,margin-right .2s ease-out .2s,margin-top .2s ease-out .2s,margin-bottom .2s ease-out .2s,opacity .2s ease-in-out,background-color .2s ease-in-out;
  transition: width .2s ease-out .2s,height .2s ease-out .2s,margin-left .2s ease-out .2s,margin-right .2s ease-out .2s,margin-top .2s ease-out .2s,margin-bottom .2s ease-out .2s,opacity .2s ease-in-out,background-color .2s ease-in-out; }

.mCS-autoHide > .mCustomScrollBox > .mCSB_scrollTools, .mCS-autoHide > .mCustomScrollBox ~ .mCSB_scrollTools {
  opacity: 0;
  filter: "alpha(opacity=0)";
  -ms-filter: "alpha(opacity=0)"; }

.mCS-autoHide:hover > .mCustomScrollBox > .mCSB_scrollTools, .mCS-autoHide:hover > .mCustomScrollBox ~ .mCSB_scrollTools, .mCustomScrollBox:hover > .mCSB_scrollTools, .mCustomScrollBox:hover ~ .mCSB_scrollTools, .mCustomScrollbar > .mCustomScrollBox > .mCSB_scrollTools.mCSB_scrollTools_onDrag, .mCustomScrollbar > .mCustomScrollBox ~ .mCSB_scrollTools.mCSB_scrollTools_onDrag {
  opacity: 1;
  filter: "alpha(opacity=100)";
  -ms-filter: "alpha(opacity=100)"; }

.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.4);
  filter: "alpha(opacity=40)";
  -ms-filter: "alpha(opacity=40)"; }

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)"; }

.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85);
  filter: "alpha(opacity=85)";
  -ms-filter: "alpha(opacity=85)"; }

.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9);
  filter: "alpha(opacity=90)";
  -ms-filter: "alpha(opacity=90)"; }

.mCSB_scrollTools .mCSB_buttonDown, .mCSB_scrollTools .mCSB_buttonLeft, .mCSB_scrollTools .mCSB_buttonRight, .mCSB_scrollTools .mCSB_buttonUp {
  background-image: none;
  background-repeat: no-repeat;
  opacity: .4;
  filter: "alpha(opacity=40)";
  -ms-filter: "alpha(opacity=40)"; }

.mCSB_scrollTools .mCSB_buttonUp {
  background-position: 0 0; }

.mCSB_scrollTools .mCSB_buttonDown {
  background-position: 0 -20px; }

.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: 0 -40px; }

.mCSB_scrollTools .mCSB_buttonRight {
  background-position: 0 -56px; }

.mCSB_scrollTools .mCSB_buttonDown:hover, .mCSB_scrollTools .mCSB_buttonLeft:hover, .mCSB_scrollTools .mCSB_buttonRight:hover, .mCSB_scrollTools .mCSB_buttonUp:hover {
  opacity: .75;
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)"; }

.mCSB_scrollTools .mCSB_buttonDown:active, .mCSB_scrollTools .mCSB_buttonLeft:active, .mCSB_scrollTools .mCSB_buttonRight:active, .mCSB_scrollTools .mCSB_buttonUp:active {
  opacity: .9;
  filter: "alpha(opacity=90)";
  -ms-filter: "alpha(opacity=90)"; }

.mCS-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15); }

.mCS-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px 0; }

.mCS-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -20px; }

.mCS-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -40px; }

.mCS-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -56px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_draggerRail, .mCS-light-2.mCSB_scrollTools .mCSB_draggerRail {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-light-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px; }

.mCS-dark-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-dark-2.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-light-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-light-2.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 4px;
  margin: 6px auto; }

.mCS-light-2.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85); }

.mCS-light-2.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-light-2.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9); }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px 0; }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -20px; }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -40px; }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -56px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-dark-2.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px 0; }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -20px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -40px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -56px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail, .mCS-light-thick.mCSB_scrollTools .mCSB_draggerRail {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-light-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.mCS-dark-thick.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-light-thick.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 4px;
  margin: 6px 0; }

.mCS-dark-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-light-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 6px;
  margin: 5px auto; }

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85); }

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-light-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9); }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -16px 0; }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -16px -20px; }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -20px -40px; }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -20px -56px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-dark-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -96px 0; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -96px -20px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -100px -40px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -100px -56px; }

.mCS-light-thin.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-light-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 2px; }

.mCS-dark-thin.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-light-thin.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%; }

.mCS-dark-thin.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-light-thin.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 2px;
  margin: 7px auto; }

.mCS-dark-thin.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-dark-thin.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px 0; }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -20px; }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -40px; }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -56px; }

.mCS-rounded.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.15); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger, .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger, .mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger, .mCS-rounded.mCSB_scrollTools .mCSB_dragger {
  height: 14px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-rounded.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 14px;
  margin: 0 1px; }

.mCS-rounded-dark.mCSB_scrollTools_horizontal .mCSB_dragger, .mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_dragger, .mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_dragger, .mCS-rounded.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 14px; }

.mCS-rounded-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-rounded.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 14px;
  margin: 1px 0; }

.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar, .mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar, .mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar, .mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  width: 16px;
  height: 16px;
  margin: -1px 0; }

.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail, .mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 4px; }

.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar, .mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar, .mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar, .mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  height: 16px;
  width: 16px;
  margin: 0 -1px; }

.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail, .mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 4px;
  margin: 6px 0; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonUp {
  background-position: 0 -72px; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonDown {
  background-position: 0 -92px; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: 0 -112px; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonRight {
  background-position: 0 -128px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar, .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px -72px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -92px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -112px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -128px; }

.mCS-rounded-dots-dark.mCSB_scrollTools_vertical .mCSB_draggerRail, .mCS-rounded-dots.mCSB_scrollTools_vertical .mCSB_draggerRail {
  width: 4px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-rounded-dots.mCSB_scrollTools .mCSB_draggerRail, .mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  background-color: transparent;
  background-position: center; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-rounded-dots.mCSB_scrollTools .mCSB_draggerRail {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAANElEQVQYV2NkIAAYiVbw//9/Y6DiM1ANJoyMjGdBbLgJQAX/kU0DKgDLkaQAvxW4HEvQFwCRcxIJK1XznAAAAABJRU5ErkJggg==);
  background-repeat: repeat-y;
  opacity: .3;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)"; }

.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  height: 4px;
  margin: 6px 0;
  background-repeat: repeat-x; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -16px -72px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -16px -92px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -20px -112px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -20px -128px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAALElEQVQYV2NkIAAYSVFgDFR8BqrBBEifBbGRTfiPZhpYjiQFBK3A6l6CvgAAE9kGCd1mvgEAAAAASUVORK5CYII=); }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -96px -72px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -96px -92px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -100px -112px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -100px -128px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-repeat: repeat-y;
  background-image: -moz-linear-gradient(left, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0, rgba(255, 255, 255, 0.5)), color-stop(100%, rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
  background-image: -o-linear-gradient(left, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
  background-image: -ms-linear-gradient(left, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%); }

.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  background-repeat: repeat-x;
  background-image: -moz-linear-gradient(top, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(255, 255, 255, 0.5)), color-stop(100%, rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
  background-image: -o-linear-gradient(top, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
  background-image: -ms-linear-gradient(top, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%); }

.mCS-3d-dark.mCSB_scrollTools_vertical .mCSB_dragger, .mCS-3d.mCSB_scrollTools_vertical .mCSB_dragger {
  height: 70px; }

.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger, .mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 70px; }

.mCS-3d-dark.mCSB_scrollTools, .mCS-3d.mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)"; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d.mCSB_scrollTools .mCSB_draggerRail {
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-3d.mCSB_scrollTools .mCSB_draggerRail {
  width: 8px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.5), inset -1px 0 1px rgba(255, 255, 255, 0.2); }

.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-3d-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-3d-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar, .mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-3d.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-3d.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #555; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 8px; }

.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-3d.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 8px;
  margin: 4px 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.5), inset 0 -1px 1px rgba(255, 255, 255, 0.2); }

.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 8px;
  margin: 4px auto; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1); }

.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1); }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

.mCS-3d-thick-dark.mCSB_scrollTools, .mCS-3d-thick.mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)"; }

.mCS-3d-thick-dark.mCSB_scrollTools, .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerContainer, .mCS-3d-thick.mCSB_scrollTools, .mCS-3d-thick.mCSB_scrollTools .mCSB_draggerContainer {
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px; }

.mCSB_inside + .mCS-3d-thick-dark.mCSB_scrollTools_vertical, .mCSB_inside + .mCS-3d-thick.mCSB_scrollTools_vertical {
  right: 1px; }

.mCS-3d-thick-dark.mCSB_scrollTools_vertical, .mCS-3d-thick.mCSB_scrollTools_vertical {
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5); }

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal, .mCS-3d-thick.mCSB_scrollTools_horizontal {
  bottom: 1px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5); }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4);
  width: 12px;
  margin: 2px;
  position: absolute;
  height: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4);
  height: 12px;
  width: auto; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-3d-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-3d-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #555; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerContainer {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1); }

.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

.mCS-3d-thick-dark.mCSB_scrollTools {
  box-shadow: inset 0 0 14px rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4), inset -1px 0 0 rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), inset 0 -1px 0 rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #777; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerContainer {
  background-color: #fff;
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1); }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-minimal-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-minimal.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

.mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical, .mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical {
  right: 0;
  margin: 12px 0; }

.mCustomScrollBox.mCS-minimal + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal, .mCustomScrollBox.mCS-minimal + .mCSB_scrollTools.mCSB_scrollTools_horizontal, .mCustomScrollBox.mCS-minimal-dark + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal, .mCustomScrollBox.mCS-minimal-dark + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: 0;
  margin: 0 12px; }

.mCS-dir-rtl > .mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical, .mCS-dir-rtl > .mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical {
  left: 0;
  right: auto; }

.mCS-minimal-dark.mCSB_scrollTools_vertical .mCSB_dragger, .mCS-minimal.mCSB_scrollTools_vertical .mCSB_dragger {
  height: 50px; }

.mCS-minimal-dark.mCSB_scrollTools_horizontal .mCSB_dragger, .mCS-minimal.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 50px; }

.mCS-minimal.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.2);
  filter: "alpha(opacity=20)";
  -ms-filter: "alpha(opacity=20)"; }

.mCS-minimal.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-minimal.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.5);
  filter: "alpha(opacity=50)";
  -ms-filter: "alpha(opacity=50)"; }

.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
  filter: "alpha(opacity=20)";
  -ms-filter: "alpha(opacity=20)"; }

.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.5);
  filter: "alpha(opacity=50)";
  -ms-filter: "alpha(opacity=50)"; }

.mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail, .mCS-light-3.mCSB_scrollTools .mCSB_draggerRail {
  width: 6px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2); }

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-light-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px; }

.mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-light-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-light-3.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 6px;
  margin: 5px 0; }

.mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail, .mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 12px; }

.mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail, .mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 12px;
  margin: 2px 0; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-dark-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1); }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-inset-2.mCSB_scrollTools .mCSB_draggerRail, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-inset-3.mCSB_scrollTools .mCSB_draggerRail, .mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-inset.mCSB_scrollTools .mCSB_draggerRail {
  width: 12px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2); }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-inset.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px;
  margin: 3px 5px;
  position: absolute;
  height: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.mCS-inset-2-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-3-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-inset.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 6px;
  margin: 5px 3px;
  position: absolute;
  width: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.mCS-inset-2-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-inset-2.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-inset-3-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-inset-3.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-inset-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-inset.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 12px;
  margin: 2px 0; }

.mCS-inset-2.mCSB_scrollTools .mCSB_buttonUp, .mCS-inset-3.mCSB_scrollTools .mCSB_buttonUp, .mCS-inset.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-inset-2.mCSB_scrollTools .mCSB_buttonDown, .mCS-inset-3.mCSB_scrollTools .mCSB_buttonDown, .mCS-inset.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-inset-2.mCSB_scrollTools .mCSB_buttonLeft, .mCS-inset-3.mCSB_scrollTools .mCSB_buttonLeft, .mCS-inset.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-inset-2.mCSB_scrollTools .mCSB_buttonRight, .mCS-inset-3.mCSB_scrollTools .mCSB_buttonRight, .mCS-inset.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar, .mCS-inset-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-inset-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-inset-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1); }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonUp, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonUp, .mCS-inset-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonDown, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonDown, .mCS-inset-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonLeft, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonLeft, .mCS-inset-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonRight, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonRight, .mCS-inset-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-inset-2.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: #fff;
  border-color: rgba(255, 255, 255, 0.2);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail {
  border-color: #000;
  border-color: rgba(0, 0, 0, 0.2); }

.mCS-inset-3.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.6); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.6); }

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-inset-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9); }

/******************************************/
/*		base 歸零 變數
/******************************************/
/* http://meyerweb.com/eric/tools/css/reset/ */
/* v1.0 | 20080212 */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
sub,
sup,
tt,
var,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
caption,
tfoot,
thead,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  /* vertical-align: baseline; */ }

tr,
th,
td {
  margin: 0;
  padding: 0;
  font-size: 100%;
  font: inherit; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

/*ol, ul {
	list-style: none;
}*/
blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

/*背景沒連結時用*/
.myMOUSE {
  cursor: default; }

.error {
  font-size: 12px;
  color: #fc7314; }

/******************************************/
/*		layout 共用
/******************************************/
/*----------------------通用設定------------------------*/
main[role^=main] {
  zoom: 1; }
  main[role^=main]:before, main[role^=main]:after {
    content: "";
    display: table;
    height: 0;
    overflow: hidden; }
  main[role^=main]:after {
    clear: both; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

p::selection, h1::selection, h2::selection, h3::selection, h4::selection, b::selection, strong::selection, span::selection, li::selection, div::selection, a::selection, img::selection {
  color: #fff;
  background: #000; }

a {
  outline: none; }

a, a:hover {
  text-decoration: none;
  transition: 0.4s;
  color: initial; }

*:focus {
  outline: none; }

img {
  vertical-align: middle;
  max-width: 100%; }

ul {
  list-style-type: none; }

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

/*----------------------編輯器------------------------*/
.editor_inner {
  font-size: 0.9375em;
  color: #707070;
  font-weight: 300;
  letter-spacing: 0.75px; }
  @media (min-width: 576px) {
    .editor_inner {
      line-height: 1.5; } }
  @media (min-width: 576px) {
    .editor_inner {
      line-height: 1.87; } }
  .editor_inner ul, .editor_inner ol {
    padding-left: 40px; }
  .editor_inner img {
    max-width: 100%;
    height: auto !important; }
  .editor_inner iframe {
    max-width: 100%; }
    @media screen and (max-width: 767px) {
      .editor_inner iframe {
        height: auto !important; } }
  .editor_inner h1, .editor_inner h2, .editor_inner h3, .editor_inner h4, .editor_inner h5, .editor_inner h6 {
    margin: inherit; }

/*----------------------頁籤------------------------*/
.page {
  margin: 0px;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center; }
  .page dt {
    margin-top: -12px; }
    .page dt i {
      display: inline-block;
      height: 1px;
      background-color: #a0a0a0;
      position: relative;
      transition: transform 0.6s;
      width: 25px; }
    .page dt.rtbn i:after {
      content: ' ';
      display: inline-block;
      width: 10px;
      height: 1px;
      transform: rotate(45deg);
      transform-origin: right;
      background-color: #a0a0a0;
      position: absolute;
      top: 0;
      right: 0; }
    .page dt.ltbn i:before {
      content: ' ';
      display: inline-block;
      width: 10px;
      height: 1px;
      transform: rotate(-45deg);
      transform-origin: left;
      background-color: #a0a0a0;
      position: absolute;
      top: 0;
      left: 0; }
    @media (min-width: 768px) {
      .page dt.rtbn a:not(.nopage):hover i {
        transform: translateX(15px); }
      .page dt.ltbn a:not(.nopage):hover i {
        transform: translateX(-15px); } }
    .page dt .nopage {
      z-index: -1;
      opacity: 0.5;
      cursor: default; }
  .page dd {
    font-family: "Microsoft JhengHei", "微軟正黑體", sans-serif;
    position: relative; }
    .page dd:nth-child(2) {
      padding-left: 15px; }
    .page dd:nth-last-child(2) {
      padding-right: 15px; }
    .page dd:after {
      content: ' ';
      display: block;
      width: 100%;
      height: 1px;
      background-color: #a0a0a0;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: -1; }
    .page dd a {
      display: block;
      color: #a0a0a0;
      position: relative; }
      .page dd a i {
        border-bottom: 2px solid rgba(0, 0, 0, 0);
        line-height: 1.5;
        display: block;
        padding: 0 2px 6px;
        transition: border 0.6s; }
      @media (max-width: 767.99px) {
        .page dd a {
          padding: 0 10px;
          margin: 0 5px; }
          .page dd a i {
            font-size: 0.75em; } }
      @media (min-width: 768px) {
        .page dd a {
          padding: 0 10px;
          margin: 0 10px; }
          .page dd a i {
            font-size: 0.9375em; }
          .page dd a:hover {
            color: black; }
            .page dd a:hover i {
              border-color: black; } }
    .page dd a.active {
      color: black; }
      .page dd a.active i {
        border-color: black; }

/*----------------------go top------------------------*/
#topBtn {
  text-align: center;
  display: block;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  width: 63px;
  height: 63px;
  -webkit-border-radius: 99em;
  -moz-border-radius: 99em;
  border-radius: 99em;
  background-color: #9d1f28;
  color: white;
  font-weight: 400;
  letter-spacing: 0.9px;
  line-height: 53px;
  padding-top: 12px;
  font-size: 1.125em;
  position: relative; }
  #topBtn:before {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 5px 8px 5px;
    border-color: transparent transparent white;
    position: absolute;
    top: 14px;
    left: 26px;
    transition: all 0.6s; }
  #topBtn.open {
    opacity: 1;
    visibility: visible;
    z-index: 100; }
  #topBtn.change {
    position: absolute; }
  @media (max-width: 575.99px) {
    #topBtn {
      transform-origin: right bottom;
      transform: scale(0.65); } }
  @media (max-width: 767.99px) {
    #topBtn {
      position: fixed;
      right: 10px;
      bottom: 60px; } }
  @media (min-width: 768px) {
    #topBtn {
      position: fixed;
      right: 30px;
      bottom: 20px; } }
  @media (min-width: 992px) {
    #topBtn:hover {
      background: #fefefe;
      color: #9d1f28; }
      #topBtn:hover::before {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 5px 8px 5px;
        border-color: transparent transparent #9d1f28; } }

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

.text-justify {
  text-align: justify; }

.mpsrel {
  position: relative; }

.hide {
  display: none; }

.bgcover {
  width: 100%;
  height: 0;
  background: center / cover; }

.rowbox {
  margin: 0 -15px 0 -15px;
  position: relative;
  max-width: 1200px;
  padding: 0 15px; }

.row.col-md-center {
  justify-content: center; }

@media (max-width: 991.99px) {
  .show-lg {
    display: none; }
  .bgcover[data-src-lg] {
    content: attr(data-src-lg, url); } }

@media (min-width: 992px) {
  .show-sm {
    display: none; }
  .bgcover[data-src-sm] {
    content: attr(data-src-sm, url); } }

header .logo {
  position: absolute;
  top: -10px;
  left: 20px;
  z-index: 10; }
  @media (max-width: 575.99px) {
    header .logo {
      width: 70px; } }
  @media (min-width: 576px) {
    header .logo {
      width: 120px; } }

header .navBox {
  position: fixed;
  top: 0%;
  right: 0;
  z-index: 990;
  min-width: 280px;
  background-color: rgba(0, 42, 100, 0.8);
  height: 100vh;
  overflow: auto;
  display: none; }

header ul.mmenu {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%); }
  header ul.mmenu > li {
    text-align: center; }
    header ul.mmenu > li a {
      position: relative;
      font-size: 1.25em;
      color: white;
      font-weight: 400;
      letter-spacing: 0.5px;
      line-height: 1.2;
      display: inline-block; }
      header ul.mmenu > li a:after {
        content: ' ';
        display: block;
        width: 100%;
        height: 1px;
        background-color: white;
        margin-top: 3px;
        opacity: 0;
        transition: opacity 0.6s; }
    @media (max-width: 767.99px) {
      header ul.mmenu > li a {
        padding: 10px 0;
        margin: 5px 0;
        display: block; } }
    @media (min-width: 768px) {
      header ul.mmenu > li {
        margin-bottom: 24px; }
        header ul.mmenu > li a:hover:after,
        header ul.mmenu > li.active a:after {
          opacity: 1; } }

header .menuicon {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 998; }
  header .menuicon .mpsrel {
    background-color: #002a64; }
    @media (max-width: 575.99px) {
      header .menuicon .mpsrel {
        width: 50px;
        height: 50px; } }
    @media (min-width: 576px) {
      header .menuicon .mpsrel {
        width: 80px;
        height: 80px; } }
  header .menuicon .bar, header .menuicon .bar:after, header .menuicon .bar:before {
    transition: all .25s;
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    height: 2px;
    width: 32px;
    background: white;
    transform: translateX(-50%);
    margin-left: 4px; }
    @media (max-width: 575.99px) {
      header .menuicon .bar, header .menuicon .bar:after, header .menuicon .bar:before {
        width: 24px; } }
  header .menuicon .bar:before {
    top: -10px;
    margin-left: -4px;
    width: 40px; }
    @media (max-width: 575.99px) {
      header .menuicon .bar:before {
        width: 32px; } }
  header .menuicon .bar:after {
    top: 10px;
    margin-left: 4px;
    width: 24px; }
    @media (max-width: 575.99px) {
      header .menuicon .bar:after {
        width: 16px; } }

header.menu-expanded .menuicon .mpsrel {
  background-color: rgba(0, 42, 100, 0); }

header.menu-expanded .menuicon .bar {
  background: rgba(0, 0, 0, 0); }
  header.menu-expanded .menuicon .bar:before {
    top: 0px;
    margin: 0;
    transform: translateX(-50%) rotate(-45deg);
    background-color: white;
    width: 32px; }
  header.menu-expanded .menuicon .bar:after {
    top: 0px;
    margin: 0;
    transform: translateX(-50%) rotate(45deg);
    background-color: white;
    width: 32px; }

.lang_tw .en {
  display: none; }

.lang_tw .tw {
  display: block; }

.lang_en .en {
  display: block; }

.lang_en .tw {
  display: none; }

/******************************************/
/*		pages
/******************************************/
body {
  font-family: "Noto Sans TC", "Microsoft JhengHei", "微軟正黑體", sans-serif;
  background-color: #002a64;
  overflow-x: hidden;
  opacity: 0;
  visibility: hidden; }
  body.fade-in {
    opacity: 1;
    transition: opacity  1.5s ease;
    visibility: visible; }
  body.modal-open {
    overflow: hidden; }
    body.modal-open .overbg, body.modal-open .overlay {
      opacity: 1; }

html.modal-open {
  overflow: hidden; }

html {
  overflow-x: hidden;
  margin-right: calc(-1 * (100vw - 100%)); }
  @media (max-width: 575.99px) {
    html {
      font-size: 14px; } }
  @media (max-width: 320px) {
    html {
      font-size: 12px; } }

body.modal_open main {
  height: calc(100vh - 40px);
  overflow: hidden; }

.row {
  max-width: 100%;
  margin: 0 auto; }

.content {
  max-width: 1200px;
  margin: 0 auto; }

.overlay {
  background-color: #002a64;
  width: 100%;
  height: 100%;
  z-index: 1001;
  opacity: 0;
  transition: opacity 1s ease;
  position: fixed;
  top: 0%;
  left: 0%; }
  .overlay .logo {
    width: 125px;
    position: absolute;
    top: 44%;
    left: 50%;
    transform: translate(-50%, -50%); }

.overbg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  z-index: 1010;
  overflow-y: scroll;
  opacity: 0;
  transition: opacity 0.5s linear;
  z-index: 1012;
  background-color: rgba(0, 0, 0, 0.8); }
  @media (max-width: 640px) {
    .overbg {
      overflow-y: hidden; } }

.modal-content {
  max-width: 767px;
  max-height: 600px;
  width: 90%;
  height: 95%;
  padding: 40px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-left: -7px;
  z-index: 999;
  display: none; }
  @media (max-width: 640px) {
    .modal-content {
      padding: 50px 2.5%;
      margin-left: 0px; } }
  .modal-content .modalBox {
    width: 100%;
    height: 100%;
    text-align: left;
    -webkit-overflow-scrolling: touch;
    background-color: #fff;
    padding: 25px 20px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    color: 0.75em;
    font-weight: #666; }
    @media (max-width: 640px) {
      .modal-content .modalBox {
        padding: 20px 10px 20px 15px; } }
  .modal-content #telbox {
    text-align: center;
    display: none;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: black; }
    .modal-content #telbox a[href^="tel:"] {
      max-width: 450px;
      width: 80%;
      color: white;
      background-color: #002a64;
      display: block;
      margin: 35px auto 0;
      padding: 15px 0; }
    .modal-content #telbox > div {
      flex: 0 0 100%; }
    .modal-content #telbox .txt {
      font-size: 1.25em;
      margin: 10px 0 0; }
  .modal-content.telbox {
    height: 400px; }
    .modal-content.telbox #telbox {
      display: flex; }
  .modal-content.agreebox .text-justify {
    display: block; }
  .modal-content .text-justify {
    display: none;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    padding-right: 10px;
    line-height: 1.5;
    text-align: initial; }
    @media (max-width: 640px) {
      .modal-content .text-justify {
        padding-right: 5px; } }
  .modal-content .close {
    cursor: pointer;
    -webkit-border-radius: 99em;
    -moz-border-radius: 99em;
    border-radius: 99em;
    z-index: 99993;
    position: absolute;
    display: block;
    width: 40px;
    height: 40px;
    top: 0px;
    right: 0px; }
    .modal-content .close:before, .modal-content .close:after {
      position: absolute;
      left: 18px;
      top: 4px;
      content: ' ';
      height: 30px;
      width: 2px;
      background-color: #909399; }
    .modal-content .close:before {
      transform: rotate(45deg); }
    .modal-content .close:after {
      transform: rotate(-45deg); }
    @media (max-width: 640px) {
      .modal-content .close {
        right: 2.5%; } }
  .modal-content h4 {
    text-align: center;
    font-size: 2em;
    color: #303133;
    margin-bottom: 36px; }
  .modal-content h6 {
    font-size: 1.5em;
    color: #002a64;
    font-weight: 700;
    margin-bottom: 12px; }

.revealOnScroll {
  opacity: 0;
  transform: translateY(60px);
  transition: opacity 1s ,transform 1s; }

.revealOnScroll.animated {
  opacity: 1;
  transform: translateY(0px); }

.indx-2 .revealOnScroll:nth-child(2) {
  transition-delay: 0.2s; }

.indx-3 .revealOnScroll:nth-child(1) {
  transition-delay: 0.2s; }

.indx-4 .imgbox.revealOnScroll {
  transform: translate(0, 0);
  transition: opacity 3s; }

.indx-5 .imgbox.revealOnScroll {
  transform: translate(0, 0);
  transition: opacity 2s; }

@media (min-width: 768px) {
  .indx-7 .team .revealOnScroll:nth-child(2) {
    transition-delay: 0.1s; }
  .indx-7 .team .revealOnScroll:nth-child(3) {
    transition-delay: 0.2s; }
  .indx-7 .team .revealOnScroll:nth-child(4) {
    transition-delay: 0.3s; } }

.indx-8 .revealOnScroll:nth-child(2) {
  transition-delay: 0.3s; }

.indx-9 .revealOnScroll:nth-child(2) {
  transition-delay: 0.3s; }

.indx-10 .revealOnScroll {
  transform: translate(0, 0);
  transition: opacity 2s; }

footer .revealOnScroll {
  transform: translate(0, 0);
  transition: opacity 2s; }

#lightcase-case {
  padding: 0 15px; }
  #lightcase-case iframe {
    max-width: calc(100vw - 30px) !important; }

#lightcase-overlay {
  background-color: rgba(0, 0, 0, 0.8); }

@media (max-width: 575.99px) {
  a[class*="lightcase-icon-"].lightcase-icon-close {
    width: 20px;
    height: 20px;
    background-size: 20px; } }

@media (min-width: 576px) {
  a[class*="lightcase-icon-"].lightcase-icon-close {
    width: 34px;
    height: 34px;
    background-size: 34px; } }

a[class*="lightcase-icon-"].lightcase-icon-close:before {
  display: none; }

.slickbox {
  position: relative; }
  .slickbox .slick-slide {
    padding: 0.5em; }

.hiddendiv {
  display: none; }

.mCSB_inside > .mCSB_container {
  margin-right: 16px; }

.mCSB_scrollTools {
  opacity: 1; }

.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #002a64;
  width: 1px; }

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 7px;
  background-color: #002a64 !important;
  opacity: 1 !important; }

.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: black; }

.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  opacity: 1 !important; }

#twzipcode input {
  display: none; }

.g-recaptcha {
  display: none; }

.form_des {
  color: black;
  font-weight: 400;
  text-align: center;
  font-size: 1.125rem;
  margin-bottom: 1.125em;
  line-height: 1.5; }
  @media (max-width: 575.99px) {
    .form_des {
      margin-bottom: 25px; }
      .form_des i {
        display: block; } }

form {
  background: repeat-y url(../images/9/form-repeat.png) center/contain;
  overflow: hidden;
  letter-spacing: 0.01em; }
  form .form-group {
    margin-bottom: 8px; }
  form input, form select, form textarea {
    outline: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    box-shadow: none;
    width: 100%;
    background-color: #e8e8e8;
    font-size: 1.125rem;
    color: black;
    font-weight: 300; }
    form input:focus, form select:focus, form textarea:focus {
      outline: none; }
  form input, form select {
    height: 33px;
    padding: 0px 8px;
    border: none; }
    form input ::-webkit-input-placeholder, form select ::-webkit-input-placeholder {
      font-size: 1rem;
      color: black;
      font-weight: 400;
      letter-spacing: 0.01em; }
    form input :-moz-placeholder, form select :-moz-placeholder {
      font-size: 1rem;
      color: black;
      font-weight: 400;
      letter-spacing: 0.01em; }
    form input ::-moz-placeholder, form select ::-moz-placeholder {
      font-size: 1rem;
      color: black;
      font-weight: 400;
      letter-spacing: 0.01em; }
    form input :-ms-input-placeholder, form select :-ms-input-placeholder {
      font-size: 1rem;
      color: black;
      font-weight: 400;
      letter-spacing: 0.01em; }
  form select::-ms-expand {
    display: none; }
  form select {
    background: url("../images/select_down.png") #e8e8e8 no-repeat calc(100% - 20px) 55%/12px;
    padding: 3px 15px 3px 8px;
    outline: none; }
  @media screen\0 {
    form select {
      background: url("../images/select_down.png") #e8e8e8 no-repeat calc(100% - 20px) 55%/12px; } }
  form label {
    line-height: 1;
    margin-bottom: 6px;
    color: black;
    font-weight: 500; }
    @media (min-width: 576px) {
      form label {
        font-size: 1rem; } }
  form .with-errors {
    flex: 0 0 100%;
    min-height: 16px;
    line-height: 1;
    letter-spacing: 0.8px;
    color: #104ed8;
    font-weight: 400;
    display: block;
    margin-top: 4px;
    font-size: 0.875em; }
  form a[type="submit"] {
    outline: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    box-shadow: none;
    line-height: 40px;
    height: 40px;
    width: 184px;
    text-align: center;
    color: black;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 1.25em;
    margin-top: 15px;
    display: inline-block;
    background-color: #b9930b;
    position: relative;
    opacity: 1; }
    form a[type="submit"] span {
      position: relative; }
    form a[type="submit"]:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: #b89109;
      /* Old browsers */
      background: -moz-linear-gradient(left, #b89109 0%, #ffee9d 20%, #ffee9d 25%, #b89109 45%, #b89109 50%, #ffee9d 70%, #ffee9d 75%, #b89109 100%);
      /* FF3.6-15 */
      background: -webkit-linear-gradient(left, #b89109 0%, #ffee9d 20%, #ffee9d 25%, #b89109 45%, #b89109 50%, #ffee9d 70%, #ffee9d 75%, #b89109 100%);
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to left, #b89109 0%, #ffee9d 20%, #ffee9d 25%, #b89109 45%, #b89109 50%, #ffee9d 70%, #ffee9d 75%, #b89109 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color1', endColorstr='$color2',GradientType=0 );
      /* IE6-9 */
      background-size: 200% 200%;
      animation: gradeffect infinite 1s linear; }
    form a[type="submit"]:hover {
      opacity: 0.5; }
  form .check_radi {
    padding-left: 36px;
    line-height: 22px;
    text-align: left;
    width: auto;
    position: relative;
    cursor: pointer;
    user-select: none; }
    @media (max-width: 575.99px) {
      form .check_radi {
        padding-left: 30px; } }
    form .check_radi input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      left: 0;
      top: 0;
      z-index: 5;
      width: 24px;
      height: 24px; }
    form .check_radi .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 24px;
      width: 24px;
      background-color: white;
      border: solid 1px black; }
    form .check_radi .checkmark:after {
      content: "";
      position: absolute;
      top: -5px;
      left: 5px;
      display: none;
      width: 24px;
      height: 24px;
      background: url("../images/agree_yes.png") no-repeat center/contain; }
    form .check_radi input:checked ~ .checkmark:after {
      display: block; }
    form .check_radi a[data-popup] {
      color: #104ed8; }
  @media (max-width: 767.99px) {
    form textarea {
      min-height: 241px; } }
  @media (min-width: 768px) {
    form textarea {
      min-height: 330px; } }
  form .textarea-wrapper {
    overflow: hidden;
    padding: 13px 11px 13px 15px;
    background-color: #e8e8e8; }
    @media (max-width: 767.99px) {
      form .textarea-wrapper {
        height: 295px; } }
    @media (min-width: 768px) {
      form .textarea-wrapper {
        height: 358px; } }
    form .textarea-wrapper textarea {
      overflow: hidden;
      border: none;
      padding: 0;
      text-rendering: auto;
      -webkit-writing-mode: horizontal-tb !important;
      cursor: text; }

@keyframes gradeffect {
  0% {
    background-position: top left; }
  100% {
    background-position: top right; } }

.logo {
  width: 100%;
  margin: 0 auto;
  position: relative; }
  .logo .mtop {
    position: absolute;
    top: 0;
    left: 0;
    animation: startmove 2.5s infinite;
    opacity: 0; }

@keyframes startmove {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.mtitle h2 {
  color: white;
  font-weight: 700;
  letter-spacing: 0.1em;
  line-height: 1; }
  @media (max-width: 575.99px) {
    .mtitle h2 {
      font-size: 2.5em; } }
  @media (min-width: 576px) {
    .mtitle h2 {
      font-size: 3em; } }

.mtitle .line {
  margin-top: 0.75em; }

.mtitle .slogn {
  font-size: 1.375em;
  line-height: 1.5;
  color: white; }
  @media (max-width: 575.99px) {
    .mtitle .slogn {
      font-size: 1.25em; } }
  @media (min-width: 768px) {
    .mtitle .slogn {
      letter-spacing: 0.1em; } }

.mtitle .mdes {
  font-size: 1.125em;
  line-height: 1.6;
  letter-spacing: 0.075em; }

.mtitle.fblue h2, .mtitle.fblue .slogn {
  color: #162f54; }

.mtitle.fblue .slogn {
  margin-top: 1em; }
  @media (max-width: 575.99px) {
    .mtitle.fblue .slogn {
      margin-bottom: 1em; } }
  @media (min-width: 576px) {
    .mtitle.fblue .slogn {
      margin-bottom: 2em; } }

.mtitle.fblue .mitem {
  line-height: 1.6;
  color: black; }
  @media (max-width: 575.99px) {
    .mtitle.fblue .mitem {
      font-size: 1em; } }
  @media (min-width: 576px) {
    .mtitle.fblue .mitem {
      font-size: 1.125em;
      letter-spacing: 0.075em; } }

.bg-repeaty {
  background: repeat-y url(../images/light_bg_2000w.jpg) center top/2000px; }

.bdr {
  display: flex;
  align-items: center;
  position: relative;
  border: 1px solid #f2b94a;
  padding: 2% 6%; }
  @media (max-width: 991.99px) {
    .bdr {
      padding: 6%;
      width: 90%;
      max-width: 500px; } }
  @media (min-width: 992px) {
    .bdr {
      padding: 2% 6%;
      height: 100%; } }
  .bdr .mtitle {
    flex: 0 0 100%;
    width: 100%; }
  .bdr:before {
    content: ' ';
    width: calc(100% + 14px);
    height: calc(100% + 14px);
    position: absolute;
    top: -7px;
    left: -7px;
    border: 2px solid #f2b94a; }
  .bdr .diamonds {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
    .bdr .diamonds > div {
      width: 60px; }
    .bdr .diamonds div:nth-child(1) {
      position: absolute;
      top: -30px;
      left: -30px; }
    .bdr .diamonds div:nth-child(2) {
      position: absolute;
      top: -30px;
      right: -30px; }
    .bdr .diamonds div:nth-child(3) {
      position: absolute;
      bottom: -15px;
      left: -30px; }
    .bdr .diamonds div:nth-child(4) {
      position: absolute;
      right: -30px;
      bottom: -15px; }

.menuTop#indx-7 {
  transform: translateY(-30px); }

.pd15-sm {
  padding: 0 15px; }
  @media (min-width: 576px) and (max-width: 991.99px) {
    .pd15-sm {
      padding: 6vh 6vw; } }

.indx-1 {
  width: 100%;
  height: 100vh;
  position: relative; }
  @media (max-width: 767.99px) {
    .indx-1 {
      background: no-repeat url(../images/1/banner_2000x1125_mb.jpg) center bottom/cover; } }
  @media (min-width: 768px) {
    .indx-1 {
      background: no-repeat url(../images/1/banner_2000x1125.jpg) center bottom/cover; } }
  .indx-1 .txt {
    margin: 0 auto; }
    @media (max-width: 575.99px) {
      .indx-1 .txt {
        position: absolute;
        top: 40%;
        left: 50%;
        width: 200px;
        transform: translate(-50%, -50%); } }
    @media (min-width: 576px) {
      .indx-1 .txt {
        position: absolute;
        top: 5%;
        left: 50%;
        width: 225px;
        transform: translateX(-50%); } }
    @media (min-width: 1600px) {
      .indx-1 .txt {
        top: 25%; } }

.indx-2 {
  overflow: hidden; }
  .indx-2 .building {
    position: relative; }
    @media (max-width: 767.99px) {
      .indx-2 .building {
        background: no-repeat url(../images/2/building.jpg) center top/cover;
        height: 280px; } }
    @media (min-width: 768px) {
      .indx-2 .building {
        background: no-repeat url(../images/2/building.jpg) center top/2000px;
        height: 528px; } }
  @media (max-width: 991.99px) {
    .indx-2 .bgimg {
      padding: 4vh 15px;
      background: no-repeat url(../images/2/bg_2000w_mb.jpg) center bottom/cover; } }
  @media (min-width: 992px) {
    .indx-2 .bgimg {
      padding: 12vh 15px;
      background: no-repeat url(../images/2/bg_2000w.jpg) center bottom/cover; } }
  .indx-2 .mtitle {
    position: relative;
    z-index: 5; }
  .indx-2 .slogn {
    margin-top: 0.875em; }
  .indx-2 .mdes {
    color: white; }
  .indx-2 .bdr {
    margin: 0 auto; }
    @media (max-width: 991.99px) {
      .indx-2 .bdr {
        padding: 4vh 0; } }
    @media (min-width: 992px) {
      .indx-2 .bdr {
        padding: 7vh 0;
        width: 88%; } }
    @media (min-height: 800px) and (min-width: 1600px) {
      .indx-2 .bdr {
        padding: 9vh 0; } }
  @media (max-width: 575.99px) {
    .indx-2 .pdlr {
      padding: 0 3%; } }
  @media (min-width: 576px) {
    .indx-2 .pdlr {
      padding: 0 6%; } }
  .indx-2 .adva {
    background: #b89109;
    /* Old browsers */
    background: -moz-linear-gradient(left, #b89109 0%, #ffee9d 20%, #ffee9d 25%, #b89109 45%, #b89109 50%, #ffee9d 70%, #ffee9d 75%, #b89109 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #b89109 0%, #ffee9d 20%, #ffee9d 25%, #b89109 45%, #b89109 50%, #ffee9d 70%, #ffee9d 75%, #b89109 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to left, #b89109 0%, #ffee9d 20%, #ffee9d 25%, #b89109 45%, #b89109 50%, #ffee9d 70%, #ffee9d 75%, #b89109 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color1', endColorstr='$color2',GradientType=0 );
    /* IE6-9 */
    background-size: 200% 200%;
    animation: gradeffect infinite 3s linear;
    width: 100%;
    font-size: 1.5em;
    letter-spacing: 0.078em;
    line-height: 1;
    padding: 0.75em 0; }
    @media (max-width: 767.99px) {
      .indx-2 .adva {
        margin-top: 1.5em; } }
    @media (min-width: 768px) {
      .indx-2 .adva {
        margin-top: 1.5em; } }
  .indx-2 .itme-3 > div {
    max-width: 44.5em;
    margin: auto; }
  @media (max-width: 767.99px) {
    .indx-2 .itme-3 > div {
      text-align: center;
      margin-top: 3em; } }
  @media (min-width: 768px) {
    .indx-2 .itme-3 > div {
      display: flex;
      align-items: center;
      margin-top: 2em; } }
  @media (min-width: 992px) {
    .indx-2 .itme-3 > div br {
      display: none; } }
  @media (max-width: 767.99px) {
    .indx-2 .itme-3 > div:nth-child(1) {
      margin-top: 2em; } }
  @media (min-width: 768px) {
    .indx-2 .itme-3 > div:nth-child(1) {
      margin-top: 1.5em; } }
  .indx-2 .itme-3 .mimg img {
    height: 60px; }
  @media (max-width: 767.99px) {
    .indx-2 .itme-3 .mimg img {
      margin-bottom: 1em; } }
  @media (min-width: 768px) {
    .indx-2 .itme-3 .mimg {
      flex: 0 0 120px;
      width: 120px;
      margin-right: 20px;
      text-align: center; } }
  .indx-2 .itme-3 .txt {
    font-size: 1.25em;
    color: white;
    letter-spacing: 0.025em;
    line-height: 1.35; }
  @media (min-width: 992px) and (max-width: 1199px) {
    .indx-2 .itme-3 {
      font-size: 12px; }
      .indx-2 .itme-3 .mimg {
        flex: 0 0 90px;
        width: 90px; }
      .indx-2 .itme-3 .mimg img {
        height: 40px; } }

.indx-3 {
  background-color: white; }
  @media (max-width: 575.99px) {
    .indx-3 {
      padding-top: 2.5em;
      padding-bottom: 1em; } }
  @media (min-width: 576px) {
    .indx-3 {
      padding: 4.5em 0 4em 0; } }
  @media (min-width: 992px) {
    .indx-3 .mflex {
      display: flex;
      margin-bottom: 3em; }
    .indx-3 .txtbox {
      flex: 0 0 43%;
      width: 43%;
      order: 2;
      margin-left: 4.3%; }
    .indx-3 .imgbox {
      flex: 0 0 48%;
      width: 48%;
      order: 1;
      overflow: hidden; } }
  @media (max-width: 991.99px) {
    .indx-3 .bdr {
      margin: 0 auto 2em; } }
  @media (min-width: 768px) and (max-width: 991.99px) {
    .indx-3 .bdr {
      margin-bottom: 5em; } }
  @media (min-width: 992px) {
    .indx-3 .bdr {
      flex: 0 0 90%;
      width: 90%; } }
  @media (max-width: 991.99px) {
    .indx-3 .imgbox {
      margin: 0 auto 0.5em; } }
  @media (min-width: 576px) and (max-width: 991.99px) {
    .indx-3 .imgbox {
      width: calc(84% - 16px); } }
  .indx-3 .slick-3 {
    max-width: 84%;
    margin: 0 auto; }
    .indx-3 .slick-3 .slick-slide {
      cursor: pointer; }
  @media (max-width: 575.99px) {
    .indx-3 .btn-slick-prev {
      left: -0.5em; } }
  @media (min-width: 576px) {
    .indx-3 .btn-slick-prev {
      left: 4%; } }
  @media (max-width: 575.99px) {
    .indx-3 .btn-slick-next {
      right: -0.5em; } }
  @media (min-width: 576px) {
    .indx-3 .btn-slick-next {
      right: 4%; } }
  .indx-3 .btn-slick-prev, .indx-3 .btn-slick-next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
    @media (min-width: 768px) {
      .indx-3 .btn-slick-prev, .indx-3 .btn-slick-next {
        display: none; } }
    .indx-3 .btn-slick-prev .btm, .indx-3 .btn-slick-next .btm {
      opacity: 1;
      transition: opacity 0.6s; }
    .indx-3 .btn-slick-prev .top, .indx-3 .btn-slick-next .top {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      transition: opacity 0.6s; }
    .indx-3 .btn-slick-prev:hover .btm, .indx-3 .btn-slick-next:hover .btm {
      opacity: 0; }
    .indx-3 .btn-slick-prev:hover .top, .indx-3 .btn-slick-next:hover .top {
      opacity: 1; }

.indx-4 .imgbox > div {
  position: relative; }

@media (max-width: 575.99px) {
  .indx-4 .logo {
    height: 22px;
    position: absolute;
    top: 0%;
    left: 306px; } }

@media (min-width: 576px) {
  .indx-4 .logo {
    position: absolute;
    top: 0%;
    left: 38.7%;
    width: 4%; } }

@media (max-width: 575.99px) {
  .indx-4 .imgbox {
    height: 350px;
    width: 100%;
    overflow: hidden;
    position: relative; }
    .indx-4 .imgbox > div {
      height: 100%;
      display: flex;
      overflow: hidden;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      animation: image-move 15s linear infinite; }
    .indx-4 .imgbox img {
      height: 100%;
      max-width: initial; } }

@keyframes image-move {
  0% {
    transform: translatex(-40%); }
  50% {
    transform: translatex(-60%); }
  100% {
    transform: translatex(-40%); } }

@media (min-width: 992px) {
  .indx-5 {
    display: flex; }
    .indx-5 .txtbox {
      flex: 0 0 49%;
      width: 49%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 2.5em; }
    .indx-5 .bdr {
      flex: 0 0 83%;
      width: 83%; }
    .indx-5 .imgbox {
      flex: 0 0 51%;
      width: 51%; } }

@media (max-width: 991.99px) {
  .indx-5 .txtbox {
    padding-top: 2em;
    padding-bottom: 2em; } }

@media (min-width: 768px) and (max-width: 991.99px) {
  .indx-5 .txtbox {
    padding-top: 4em;
    padding-bottom: 4em; } }

@media (max-width: 991.99px) {
  .indx-5 .bdr {
    margin: 0 auto; } }

.indx-6 {
  position: relative;
  text-align: center;
  z-index: 10;
  margin-top: -5px; }
  @media (max-width: 991.99px) {
    .indx-6 .mtitle {
      padding-top: 2em;
      padding-bottom: 4em;
      background: no-repeat url(../images/6/view.png) center bottom/cover; } }
  @media (min-width: 768px) and (max-width: 991.99px) {
    .indx-6 .mtitle {
      padding-top: 4em;
      padding-bottom: 8em; } }
  @media (min-width: 992px) {
    .indx-6 .mtitle {
      max-width: 800px;
      width: calc(100% - 40px);
      position: absolute;
      top: 15vh;
      left: 50%;
      transform: translateX(-50%); } }
  @media (max-width: 991.99px) {
    .indx-6 .mimg {
      display: none; } }
  .indx-6 .wline {
    width: 100%;
    height: 2px;
    background-color: white; }
    @media (max-width: 991.99px) {
      .indx-6 .wline {
        max-width: 600px;
        margin: 1.56em auto; } }
    @media (min-width: 992px) {
      .indx-6 .wline {
        margin: 1.56em 0; } }
  .indx-6 .mdes {
    max-width: 31em;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    color: white;
    font-weight: 500;
    text-shadow: 0 0 9.6px rgba(0, 0, 0, 0.45);
    padding: 1.25em 1.25em;
    margin: 1.125em auto 0; }

@media (max-width: 767.99px) {
  .sucbg {
    padding-bottom: 2em;
    padding-top: 90px;
    background: no-repeat url(../images/7/success_bg_mb.jpg) #162142 center top/cover; } }

@media (min-width: 768px) {
  .sucbg {
    background: no-repeat url(../images/7/success_bg.jpg) #162142 center top/cover;
    padding-top: 160px; } }

@media (min-width: 992px) {
  .sucbg {
    padding-bottom: 130px; } }

.sucbg .powder {
  background: no-repeat url(../images/7/gold_powder.png) center top;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0; }
  @media (max-width: 767.99px) {
    .sucbg .powder {
      background-size: 1100px;
      top: -60px; } }
  @media (min-width: 768px) {
    .sucbg .powder {
      background-size: 1900px;
      top: -80px; } }

.indx-7 {
  line-height: 1.25;
  color: white;
  font-weight: 500;
  letter-spacing: 0.075em; }
  .indx-7 .slogn {
    margin-top: 0.875em;
    margin-bottom: 3em; }
  .indx-7 .team {
    display: flex;
    justify-content: space-around; }
    .indx-7 .team > div {
      position: relative; }
    @media (max-width: 991.99px) {
      .indx-7 .team {
        flex-wrap: wrap; }
        .indx-7 .team > div {
          flex: 0 0 50%;
          margin-bottom: 3em; } }
    @media (min-width: 768px) and (max-width: 991.99px) {
      .indx-7 .team {
        padding: 15px; } }
    @media (min-width: 992px) {
      .indx-7 .team > div:nth-child(1) {
        padding-right: 25px; }
      .indx-7 .team > div:nth-child(4) {
        padding-left: 30px; } }
  .indx-7 .mname {
    position: absolute;
    top: 3em; }
  @media (max-width: 991.99px) {
    .indx-7 .p1 {
      left: 0em; } }
  @media (min-width: 992px) {
    .indx-7 .p1 {
      left: 0; } }
  @media (max-width: 767.99px) {
    .indx-7 .p2 {
      left: -1em; }
      .indx-7 .p2 + .mimg img {
        margin-left: 10%; } }
  @media (min-width: 768px) {
    .indx-7 .p2 {
      left: -0.7em; } }
  @media (max-width: 767.99px) {
    .indx-7 .p3 {
      left: 0em; } }
  @media (min-width: 768px) {
    .indx-7 .p3 {
      left: 1.5em; } }
  @media (max-width: 767.99px) {
    .indx-7 .p4 {
      left: 1em; }
      .indx-7 .p4 + .mimg img {
        margin-left: 10%; } }
  @media (min-width: 768px) {
    .indx-7 .p4 {
      left: 4.5em; } }
  .indx-7 .mname div:nth-child(1) {
    font-size: 1rem; }
  .indx-7 .mname div:nth-child(2) {
    font-size: 1.25rem; }
  .indx-7 .mimg {
    text-align: center; }
    @media (max-width: 991.99px) {
      .indx-7 .mimg img {
        width: 90%; } }
  .indx-7 .com div:nth-child(1) {
    line-height: 1.3; }
    @media (max-width: 575.99px) {
      .indx-7 .com div:nth-child(1) {
        font-size: 1.125rem;
        margin-bottom: 0.25em;
        margin-top: 0.5em; } }
    @media (min-width: 576px) {
      .indx-7 .com div:nth-child(1) {
        font-size: 1.5rem;
        margin-bottom: 0.01em;
        margin-top: 1.25em; } }
  .indx-7 .com div:nth-child(2) {
    font-size: 1rem; }

.indx-8 {
  margin-top: 5em; }
  @media (min-width: 576px) and (max-width: 991.99px) {
    .indx-8 {
      margin-top: -6em; } }
  .indx-8 .col-12 {
    padding-left: 0;
    padding-right: 0; }
  @media (min-width: 576px) and (max-width: 991.99px) {
    .indx-8 .mtitle {
      width: 90%;
      margin-left: 0.5em;
      margin-bottom: 0.5em; } }
  .indx-8 .flex-center {
    align-items: center;
    display: flex; }
  .indx-8 .slogn {
    margin-top: 1em; }
  .indx-8 .mdes {
    color: white;
    font-size: 1.125rem;
    line-height: 1.6;
    letter-spacing: 0.075em;
    margin-top: 2.5em; }
  .indx-8 .mflex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around; }
  @media (max-width: 767.99px) {
    .indx-8 .flex-4 {
      width: 100%;
      flex: 0 0 100%; }
      .indx-8 .flex-4 img {
        width: 100%; } }
  @media (min-width: 768px) {
    .indx-8 .flex-4 {
      flex: 0 0 50%;
      width: 50%;
      margin-top: 0.5em;
      margin-bottom: 0.5em; } }
  .indx-8 .flex-2 {
    flex: 0 0 50%;
    width: 50%; }
    @media (max-width: 767.99px) {
      .indx-8 .flex-2 .txt {
        width: calc(100% - 0.5em); }
      .indx-8 .flex-2:nth-child(1) {
        padding-right: 0.5em; }
        .indx-8 .flex-2:nth-child(1) .txt {
          left: 0; }
      .indx-8 .flex-2:nth-child(2) {
        padding-left: 0.5em; }
        .indx-8 .flex-2:nth-child(2) .txt {
          left: 0.5em; } }
  .indx-8 .flex-4:not(.mflex), .indx-8 .flex-2 {
    position: relative; }
    @media (max-width: 767.99px) {
      .indx-8 .flex-4:not(.mflex), .indx-8 .flex-2 {
        margin-top: 1em; } }
    @media (min-width: 768px) {
      .indx-8 .flex-4:not(.mflex), .indx-8 .flex-2 {
        padding-left: 0.5em;
        padding-right: 0.5em; } }
  .indx-8 .mimg {
    overflow: hidden; }
    .indx-8 .mimg img {
      transform: scale(1);
      transition: transform 0.6s; }
      .indx-8 .mimg img:hover {
        transform: scale(1.2); }
  .indx-8 .txt {
    text-align: center;
    height: 2em;
    line-height: 2em;
    letter-spacing: 0.1em;
    font-size: 1rem;
    color: white;
    background-color: rgba(0, 0, 0, 0.75); }
    @media (max-width: 767.99px) {
      .indx-8 .txt {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0; } }
    @media (min-width: 768px) {
      .indx-8 .txt {
        width: calc(100% - 1em);
        position: absolute;
        bottom: 0;
        left: 0.5em; } }

.indx-9 {
  background: no-repeat url(../images/9/form_bg_2000px.png) center top/2000px; }
  @media (max-width: 767.99px) {
    .indx-9 {
      padding-bottom: 50px;
      padding-top: 10px;
      background-size: cover; } }
  @media (min-width: 768px) {
    .indx-9 {
      padding-bottom: 100px;
      padding-top: 60px; } }
  .indx-9 .mtitle {
    margin-bottom: 2.5em; }
  .indx-9 .logo {
    width: 65px;
    margin-bottom: 12px; }
  .indx-9 .content {
    position: relative; }
    @media (max-width: 767.99px) {
      .indx-9 .content {
        padding: 20px 0; } }
    @media (min-width: 768px) {
      .indx-9 .content {
        padding: 60px 0; } }
    .indx-9 .content:before, .indx-9 .content:after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0; }
    .indx-9 .content:before {
      background: no-repeat url(../images/9/form-top.png) center top/contain;
      top: 0; }
    .indx-9 .content:after {
      background: no-repeat url(../images/9/form-btm.png) center bottom/contain;
      bottom: 0; }
  @media (max-width: 767.99px) {
    .indx-9 form.row {
      padding-bottom: 20px; } }
  .indx-9 .col-12 {
    position: relative;
    z-index: 10; }
    @media (max-width: 767.99px) {
      .indx-9 .col-12 {
        padding-left: 25px;
        padding-right: 25px; } }

.indx-10 {
  overflow-x: hidden; }
  .indx-10 .logo {
    width: 100px;
    margin: 0 auto 30px; }
  .indx-10 .map {
    position: relative; }
    .indx-10 .map img {
      position: absolute;
      top: 0;
      transform: translateX(-50%); }
    @media (max-width: 575.99px) {
      .indx-10 .map {
        height: 252px; }
        .indx-10 .map img {
          left: 30%;
          max-width: 1400px; } }
    @media (min-width: 576px) {
      .indx-10 .map {
        height: 360px; }
        .indx-10 .map img {
          left: 50%;
          max-width: 2000px; } }
  .indx-10 .infobox {
    background: no-repeat url(../images/10/bg.jpg) center/cover;
    padding-top: 10px;
    padding-bottom: 50px;
    transform: translateY(-4.5em); }
    @media (max-width: 767.99px) {
      .indx-10 .infobox {
        display: none; } }
  .indx-10 .info {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    .indx-10 .info a.w30 {
      flex: 0 0 calc((100% - 40px) / 3);
      margin-bottom: 15px; }

@media (max-width: 767.99px) {
  .f_info .infobtn {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 99;
    display: flex;
    justify-content: space-around; }
    .f_info .infobtn a {
      background-color: #002a64;
      flex: 0 0 25%;
      border: 1px solid rgba(255, 255, 255, 0);
      text-align: center;
      padding: 18px 12px;
      font-size: 0.875em;
      color: white;
      font-weight: 400; } }

@media (min-width: 768px) {
  .f_info .infobtn {
    display: none; } }

@media (max-width: 767.99px) {
  footer {
    padding-top: 2em;
    padding-bottom: 4em; } }

footer ul.temp {
  font-size: 1rem;
  color: #002a64;
  margin-top: 3em;
  line-height: 1.5;
  display: flex;
  flex-wrap: wrap; }
  footer ul.temp li {
    display: flex; }
    @media (max-width: 767.99px) {
      footer ul.temp li {
        width: 100%;
        flex: 0 0 100%;
        margin-bottom: 1em; } }
    @media (min-width: 768px) {
      footer ul.temp li {
        width: 50%;
        flex: 0 0 50%;
        letter-spacing: 0.1em;
        margin-bottom: 2em; } }
  footer ul.temp i {
    color: #002a64;
    font-weight: 400;
    flex: 0 0 6em; }
    @media (min-width: 768px) {
      footer ul.temp i {
        margin-right: 1em; } }
  footer ul.temp i:before {
    content: ' ';
    display: inline-block;
    width: 2px;
    height: 1em;
    margin-right: 12px;
    background-color: #002a64;
    vertical-align: -0.15em; }

//overwrite
body{    
    // background:repeat-y url(../images/light_bg_2000w.jpg) center / 2000px;
    font-family: $mfont; 
    background-color: $blue;   
    // font-size: pxToEm(16);
    // line-height: 1.5;
    // @include font-color(#707070,300);
    overflow-x: hidden;
    opacity: 0;
    visibility: hidden;
    &.fade-in{
        opacity: 1;
        transition:opacity  1.5s ease;
        visibility: visible;
    }
    &.modal-open{
        // overflow: hidden;
        // height: 100vh;        
        overflow: hidden;
        // width: 100%;
        // height: 100%;
        // position: fixed;
        .overbg,.overlay{
            opacity: 1;         
        }
        // @media (min-width:641px){
        //     padding-right: 17px;
        // }
    }
}
html.modal-open{
    // height: 100vh;
    overflow: hidden;
}
html{
    overflow-x:hidden;
    margin-right: calc(-1 * (100vw - 100%));
    @include media-breakpoint-down(xs){
        font-size: 14px;
    }
    @media (max-width:320px){
        font-size: 12px;
    }
}
body{    
    &.modal_open main{
        height: calc(100vh - 40px);
        overflow: hidden;
    }
    // @media (min-width:1600px){
    //     font-size:20px;
    // }
}
.row{
    max-width: 100%;
    margin:0 auto;
}
.content{
    max-width: 1200px;
    margin:0 auto;
}
.overlay{
    background-color: $blue;
    width:100%;
    height: 100%;    
    z-index: 1001;
    opacity: 0;
    transition: opacity 1s ease;
    @include position(fixed,$top:0%,$left:0%);
    .logo{
        width:125px;
        @include position(absolute,$top:44%,$left:50%);
        transform: translate(-50%,-50%);
    }
}
.overbg{   
    position: fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    display:none;
    z-index: 1010;
    overflow-y: scroll;
    opacity: 0;
    transition: opacity 0.5s linear;    
    z-index: 1012; 
    background-color:rgba(black, 0.8);
    @media (max-width:640px){
        overflow-y: hidden;
    }
}
.modal-content{
    max-width: 767px;
    max-height: 600px;
    width:90%;
    height: 95%;
    padding:40px;
    position: fixed;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    margin-left: -7px;    
    z-index: 999;
    display: none;
    @media (max-width:640px){
        padding: 50px 2.5%;
        margin-left: 0px; 
    }
    .modalBox{
        width: 100%;
        height:100%;
        text-align:left;
        -webkit-overflow-scrolling:touch;
        background-color: #fff;        
        padding: 25px 20px;
        @include border(10px);
        @include font-color(pxToEm(12),#666);
        @media (max-width:640px){
            padding:20px 10px 20px 15px;
        }
    }
    #telbox{
        text-align: center;
        display: none;
        align-items: center;
        justify-content: center;
        height: 100%;
        color:black;
        a[href^="tel:"]{
            max-width:450px;
            width: 80%;            
            color:white;
            background-color: $blue;
            display: block;
            margin:35px auto 0;
            padding: 15px 0;
        }
        > div{
            flex:0 0 100%;
        }
        .txt{
            font-size: pxToEm(20);
            margin:10px 0 0;
        }
    }
    
    &.telbox{
        height: 400px;
        #telbox{
            display: flex;
        }
    }
    &.agreebox .text-justify{
        display: block;
    }
    .text-justify{
        display: none;
        overflow-y:auto; 
        width: 100%;
        height:100%;
        padding-right: 10px;
        line-height: 1.5;
        text-align: initial;
        @media (max-width:640px){
            padding-right: 5px;
        }
    }
    .close{
        cursor: pointer;
        // border:2px solid #909399;
        @include border();
        z-index: 99993;
        position: absolute;
        display: block;
        width:40px;
        height: 40px;
        top:0px;
        right:0px;
        &:before, &:after {
            position: absolute;
            left: 18px;
            top:4px;
            content: ' ';
            height: 30px;
            width: 2px;
            background-color: #909399;
        }
        &:before {
            transform: rotate(45deg);
        }
        &:after {
            transform: rotate(-45deg);
        }
        @media(max-width: 640px){
            right:2.5%;
        }
    }
    //內容樣式
    h4{
        text-align: center;
        font-size: pxToEm(32);
        color:#303133;
        margin-bottom: 36px;
    }
    h6{
        font-size: pxToEm(24);
        @include font-color($blue,700);
        margin-bottom: 12px;
    }
}
.revealOnScroll{
    opacity: 0;
    transform: translateY(60px);
    transition: opacity 1s ,transform 1s;
}
.revealOnScroll.animated{
    opacity: 1;
    transform: translateY(0px);
}
.indx-2{
    .revealOnScroll:nth-child(2){
        transition-delay: 0.2s;
    } 
}
.indx-3{
    .revealOnScroll:nth-child(1){
        transition-delay: 0.2s;
    } 
}
.indx-4{
    .imgbox.revealOnScroll{
        transform: translate(0,0);
        transition:opacity 3s;
    }
}
.indx-5{
    .imgbox.revealOnScroll{
        transform: translate(0,0);
        transition:opacity 2s;
    } 
}
.indx-7{
    @include media-breakpoint-up(md){
        .team{
            .revealOnScroll:nth-child(2){
                transition-delay: 0.1s;
            } 
            .revealOnScroll:nth-child(3){
                transition-delay: 0.2s;
            } 
            .revealOnScroll:nth-child(4){
                transition-delay: 0.3s;
            } 
        }
    }
}
.indx-8{
    .revealOnScroll:nth-child(2){
        transition-delay: 0.3s;
    }
}
.indx-9{
    .revealOnScroll:nth-child(2){
        transition-delay: 0.3s;
    }
}
.indx-10{
    .revealOnScroll{
        transform: translate(0,0);
        transition:opacity 2s;
    }
}
footer{
    .revealOnScroll{
        transform: translate(0,0);
        transition:opacity 2s;
    }
}
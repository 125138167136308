header{    
    //mmenu
    .logo{
        @include position(absolute,$top:-10px,$left:20px);
        z-index: 10;
        @include media-breakpoint-down(xs){
            width:70px;
        }
        @include media-breakpoint-up(sm){
            width:120px;
        }
    }
    .navBox{
        @include position(fixed,$top:0%,$right:0);
        z-index: 990;
        min-width: 280px;
        background-color:rgba($blue,0.8) ;
        height: 100vh;
        overflow:auto;
        display: none;
    }
    ul.mmenu{   
        width:100%;
        @include position(absolute,$top:50%,$left:0);
        transform: translateY(-50%);        
		> li{            
            text-align: center;
            a{
                position: relative;
                font-size: pxToEm(20);
                @include font-color(white,400);
                letter-spacing: 0.5px;
                line-height: 1.2;  
                display: inline-block;
                &:after{
                    content: ' ';
                    display: block;
                    width:100%;
                    height: 1px;
                    background-color: white;
                    margin-top: 3px;
                    opacity: 0;
                    transition: opacity 0.6s;
                }
            }
            @include media-breakpoint-down(sm){                
                a{
                    padding: 10px 0;
                    margin:5px 0;
                    display: block;
                }
            }
            @include media-breakpoint-up(md){
                margin-bottom: 24px;
                a:hover:after,
                &.active a:after{
                    opacity: 1;
                }
            }    
        }
    }//end mmenu    
    
    // .menubox{        
    //     background-color: $blue;
    //     color:white;
    //     text-align: center;
    //     @include position(fixed,$right:0,$top:0);
    //     z-index: 999;
    //     cursor: pointer;
    //     opacity: 1;
    //     transition:opacity;
    //     -webkit-overflow-scrolling: touch;
    //     @include media-breakpoint-down(xs){
    //         width:50px;
    //         height:50px;
    //         font-size: pxToEm(14);
    //         padding:14px 0 ;
    //     }
    //     @include media-breakpoint-up(sm){
    //         width:80px;
    //         height: 80px;
    //         font-size: pxToEm(16);
    //         letter-spacing: 0.4px;
    //         line-height: 36px;
    //         padding:22px 0 ;
    //     }
    // }
    .menuicon{
        @include position(fixed,$right:0,$top:0);
        z-index: 998;
        .mpsrel{
            background-color: $blue;
            @include media-breakpoint-down(xs){                
                width:50px;
                height: 50px;
            }
            @include media-breakpoint-up(sm){
                width:80px;
                height: 80px;
            }
        }
        .bar,.bar:after,.bar:before{
            transition: all .25s;
            content: "";
            position: absolute;
            left: 50%;	  
            top:50%;
            height: 2px;
            width: 32px;
            background:white;
            transform: translateX(-50%);
            margin-left: 4px;
            @include media-breakpoint-down(xs){
                width:24px;
            }
        }
        .bar{
            &:before{
                top:-10px;   
                // left:12px; 
                margin-left: -4px;
                width: 40px;
                @include media-breakpoint-down(xs){
                    width:32px;
                    // left:8px;
                }
            }
            &:after{
                top:10px;
                // left:20px;
                margin-left: 4px;
                width: 24px;
                @include media-breakpoint-down(xs){
                    width:16px;
                    // left:16px;
                }
            }
        }
    }
    // //mobile meun open
    &.menu-expanded .menuicon{
        .mpsrel{
            background-color: rgba($blue,0);
        }
        .bar{
            background:rgba(black,0);            
            &:before{
                top:0px;
                // left: 50%;	
                margin:0;  
                transform: translateX(-50%) rotate(-45deg);
                background-color: white;
                width:32px;
            }
            &:after{
                top:0px;
                // left: 50%;
                margin:0;	  
                transform: translateX(-50%) rotate(45deg);
                background-color: white;
                width:32px;
            }
        }
    }
}

//overwirte lightcase
#lightcase-case{
    padding:0 15px;
    iframe{
        max-width:calc(100vw - 30px) !important;
    }
}
#lightcase-overlay{
    background-color: rgba($color: #000000, $alpha: 0.8);
}
a[class*="lightcase-icon-"].lightcase-icon-close{
    // background: url(../svg/lighcase-close.svg) no-repeat center;    
    @include media-breakpoint-down(xs){
        width:20px;
        height: 20px;
        background-size: 20px;
    }
    @include media-breakpoint-up(sm){
        width:34px;
        height: 34px;
        background-size: 34px;
    }
    &:before{
        display: none;
    }
}

//slick next prev btn and dots
.slickbox{
    position: relative;
    //overwrite
    .slick-slide{
        padding:0.5em;
        // @include media-breakpoint-down(sm){
        //     padding:0.25em;
        // }
        // @include media-breakpoint-up(sm){
            
        // }
    }
}

.hiddendiv{
    display: none;
}
//overwrite plugin scroll
.mCSB_inside > .mCSB_container{
    margin-right: 16px;
}
.mCSB_scrollTools{
    opacity: 1;
}
.mCSB_scrollTools .mCSB_draggerRail{
    background-color: $blue;
    width:1px;
}
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{
    width:7px;
    background-color: $blue !important;
    opacity: 1 !important;
}
.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar{
    background-color: black;
}
.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar{
    opacity: 1 !important;
}

//plugin twzipcode
#twzipcode input{
    display: none;
}
//google recaptcha
.g-recaptcha{
    display: none;
}